<template>
    <b-jumbotron class="bg-darkblue" ref="contact" id="contact">
        <b-row>
            <div class="svg-wrapper">
                <svg height="87" width="116" viewBox="0 0 116 87" xmlns="http://www.w3.org/2000/svg"><path d="m80.706 82.298-39.799-18.94 68.18-49.691-28.381 68.63zm-44.177-19.903a1.68 1.68 0 0 0 -.481.51 1.432 1.432 0 0 0 -.197.581l-2.544 18.89-5.637-23.884 61.76-34.653-52.9 38.556zm-30.416-16.003 90.949-30.624-71.505 40.122zm108.272-38.87c-.515-.435-1.275-.57-1.944-.344l-111.27 37.466c-.662.223-1.116.756-1.166 1.37-.051.613.31 1.196.929 1.498l23.06 11.264 8.002 33.916a1.491 1.491 0 0 0 .367.704c.282.305.682.519 1.148.584a2.128 2.128 0 0 0 .293.02c.35 0 .682-.087.964-.239a1.771 1.771 0 0 0 .343-.238l17.358-15.087c.715-.622.714-1.63-.003-2.25-.718-.62-1.879-.618-2.594.004l-13.635 11.852 2.946-21.876 41.641 19.816a2.083 2.083 0 0 0 1.445.12 1.803 1.803 0 0 0 1.027-.72l.033-.051c.047-.079.086-.156.116-.234l31.386-75.9c.243-.586.069-1.242-.446-1.676z" fill="#82cff4" fill-rule="evenodd" transform="translate(.412 -7)"/></svg>
            </div>
            <h2 class="text-center">{{ $t('index.contact.title') }}</h2>
            <p class="big text-center">{{ $t('index.contact.txt1') }} <a class="on-underline" :href="'tel:' + $t('index.contact.phoneformatted')">{{ $t('index.contact.phone') }}</a> {{ $t('index.contact.txt2') }} <a class="on-underline" :href="'mailto:' + $t('index.contact.email')">{{ $t('index.contact.email') }}</a>.</p>
        </b-row>
    </b-jumbotron>
</template>

<script>
    let validator = require("validator");
    import { elementInViewport } from '~/assets/js/utils';

    export default {
        name: 'contact',
        data() {
            return {
                validator,
                phone: "",
                mail: "",
                message: "",
                successMessage: "",
                errorMessages: "",
                serverErrorMessage: false,
                btnLoading: false,

                rules: {
                    // vuetify expects a method taking the value as only parameter
                    // this function returns such a function
                    required: message => {
                        return value => {
                            if (!value) return message;
                            return !this.validator.isEmpty(value) || message;
                        };
                    },
                    email: message => {
                        return value => {
                            if (!value) return message;
                            return this.validator.isEmail(value) || message;
                        };
                    },
                    phone: message => {
                        return value => {
                          if (!value) return message;
                          const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
                          return regex.test(value) || message;
                        };
                    }
                },
                defaultFormFields: {
                    mail: this.mail,
                    phone: this.phone,
                    message: this.message
                }
            };
        },
        mounted () {
            let _scope = this;

            window.addEventListener('scroll', function () {
                if(_scope.$refs.contact) {

                    if(elementInViewport(_scope.$refs.contact)) {
                        _scope.$store.commit('handleContactFormInViewport', true);
                    } else {
                        _scope.$store.commit('handleContactFormInViewport', false);
                    }
                }
            }, {passive: true});
        },
        methods: {
            submit: async function(e) {
                e.preventDefault();

                this.btnLoading = true;
                this.formHasErrors = false;
                this.serverErrorMessage = false;

                Object.keys(this.defaultFormFields).forEach(f => {
                    if (!this.$refs[f].validate(true)) {
                        this.formHasErrors = true;
                    }
                });

                let params = {
                    mail: this.mail,
                    phone: this.phone,
                    message: this.message
                };

                if (this.formHasErrors){
                  this.btnLoading = false;
                  return;
                }

                try {
                    let response = await this.$axios.$post("/requests", params);

                    if (response.ok === true) {
                        this.successMessage = true;
                        this.formSendTracking();
                        this.scrollToTop();
                    }
                } catch (error) {
                    this.btnLoading = false;

                    if (error.response) {
                        this.serverErrorMessage = true;
                        this.scrollToTop();
                    }
                }
            },

            formSendTracking() {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                'event': 'form-submit'
              });
            },

            scrollToTop() {
                let el = this.$refs.contact;

                if ('scrollBehavior' in document.documentElement.style) {
                    return window.scrollTo({ top: el.getBoundingClientRect().top+window.scrollY, behavior: 'smooth' })
                } else {
                    return window.scrollTo(0, el.getBoundingClientRect().top+window.scrollY)
                }
            }
        }
    }
</script>

<style lang="scss">

    #contact {

        .svg-wrapper {
            margin: 0 auto;
            width: 115px;
            height: 86px;

            & + * {
                margin-top: 25px;
            }
        }
    }

    @media only screen and ($breakpoint768) {

        #contact {

            .svg-wrapper {
                width: 120px;
                height: 90px;

                & + * {
                    margin-top: 36px;
                }
            }
        }
    }

    @media only screen and ($breakpoint1000) {

        #contact {

            .svg-wrapper {
                width: 160px;
                height: 120px;
            }
        }
    }

    @media only screen and ($breakpoint1200) {

        #contact {

            .svg-wrapper {
                width: 190px;
                height: 142px;
            }
        }
    }
</style>