<template>
	<div id="overflow-wrapper">
		<nuxt/>
    </div>
</template>

<script>

    export default {
        head() {
			return {
				htmlAttrs: {
					lang: this.$i18n.locale
				}
			}
		}
    }
</script>
