<template>
    <b-jumbotron id="customer" class="bg-white">
        <b-row>
            <h2 class="text-center">{{ $t('index.customer.title') }}</h2>
            <div class="customer-slider">
                <div ref="slider" class="slider">
                    <div class="slide-item">
                        <figure class="image-wrapper">
                            <img class="lazyload"
                                 width="100"
                                 height="29"
                                 alt="Kunde: Berliner Philharmoniker"
                                 :src="require('~/assets/img/customers/2022-04-25_ON_customer-bph.png?size=20').src"
                                 data-src="/img/customers/2022-04-25_ON_customer-bph.svg" />
                        </figure>
                        <figure class="image-wrapper">
                            <img class="lazyload"
                                 width="100"
                                 height="50"
                                 alt="Kunde: Tropical Islands"
                                 :src="require('~/assets/img/customers/2017-08-30_ON_customer-ti.png?size=20').src"
                                 data-src="/img/customers/2017-08-30_ON_customer-ti.svg" />
                        </figure>
                        <figure class="image-wrapper">
                            <img class="lazyload"
                                 width="100"
                                 height="47"
                                 alt="Kunde: Kieser Training"
                                 :src="require('~/assets/img/customers/2017-08-30_ON_customer-kies.png?size=20').src"
                                 data-src="/img/customers/2017-08-30_ON_customer-kies.svg" />
                        </figure>
                        <figure class="image-wrapper">
                            <img class="lazyload"
                                 width="100"
                                 height="40"
                                 alt="Kunde: PADERHALLE"
                                 :src="require('~/assets/img/customers/2019-12-10_ON_customer-pad.png?size=20').src"
                                 data-src="/img/customers/2019-12-10_ON_customer-pad.svg" />
                        </figure>
                        <figure class="image-wrapper">
                            <img class="lazyload"
                                 width="100"
                                 height="58"
                                 alt="Kunde: Orchestral Tools"
                                 :src="require('~/assets/img/customers/2019-12-05_ON_customer-orch.png?size=20').src"
                                 data-src="/img/customers/2019-12-05_ON_customer-orch.svg" />
                        </figure>
                        <figure class="image-wrapper">
                            <img class="lazyload"
                                 width="100"
                                 height="33"
                                 alt="Kunde: Campe Bildungszentrum"
                                 :src="require('~/assets/img/customers/2017-08-30_ON_customer-cmp.png?size=20').src"
                                 data-src="/img/customers/2017-08-30_ON_customer-cmp.svg" />
                        </figure>
                    </div>
                    <div class="slide-item">
                        <figure class="image-wrapper">
                            <img class="lazyload"
                                 width="100"
                                 height="10"
                                 alt="Kunde: Thüringer Bachwochen"
                                 :src="require('~/assets/img/customers/2022-03-17_ON_customer-thue.png?size=20').src"
                                 data-src="/img/customers/2022-03-17_ON_customer-thue.svg" />
                        </figure>
                        <figure class="image-wrapper">
                            <img class="lazyload"
                                 width="100"
                                 height="29"
                                 alt="Kunde: FSE Gruppe"
                                 :src="require('~/assets/img/customers/2020-03-12_ON_customer-fse.png?size=20').src"
                                 data-src="/img/customers/2020-03-12_ON_customer-fse.svg" />
                        </figure>
                        <figure class="image-wrapper">
                            <img class="lazyload"
                                 width="94"
                                 height="25"
                                 alt="Kunde: OD-OS"
                                 :src="require('~/assets/img/customers/2017-08-30_ON_customer-odos.png?size=20').src"
                                 data-src="/img/customers/2017-08-30_ON_customer-odos.svg" />
                        </figure>
                        <figure class="image-wrapper">
                            <img class="lazyload"
                                 width="100"
                                 height="36"
                                 alt="Kunde: Spreetours"
                                 :src="require('~/assets/img/customers/2017-08-30_ON_customer-spr.png?size=20').src"
                                 data-src="/img/customers/2017-08-30_ON_customer-spr.svg" />
                        </figure>
                        <figure class="image-wrapper">
                            <img class="lazyload"
                                 width="100"
                                 height="36"
                                 alt="Kunde: Schützenhof"
                                 :src="require('~/assets/img/customers/2017-08-30_ON_customer-schue.png?size=20').src"
                                 data-src="/img/customers/2017-08-30_ON_customer-schue.svg" />
                        </figure>
                        <figure class="image-wrapper">
                            <img class="lazyload"
                                 width="100"
                                 height="35"
                                 alt="Kunde: Schule des Schreibens"
                                 :src="require('~/assets/img/customers/2022-04-25_ON_customer-sds.png?size=20').src"
                                 data-src="/img/customers/2022-04-25_ON_customer-sds.svg" />
                        </figure>
                    </div>
                </div>
            </div>
        </b-row>
    </b-jumbotron>
</template>

<script>
    export default {
        name: 'customer',
        mounted () {
            this.$nextTick(() => {
                let module = require('tiny-slider/src/tiny-slider.module');

                module.tns({
                    container: this.$refs.slider,
                    items: 1,
                    nav: false,
                    loop: true,
                    preventScrollOnTouch: 'auto',
                    controlsPosition: 'bottom',
                    controlsText: ["zurück", "weiter"],
                    responsive: {
                        768: {
                            edgePadding: 62,
                        },
                        1000: {
                            edgePadding: 92,
                        },
                        1200: {
                            edgePadding: 112,
                        },
                        1700: {
                            edgePadding: 65,
                        }
                    }
                });
            });
        }
    }
</script>

<style lang="scss">

    .customer-slider {
        margin: 25px -25px -20px;

        .slider {

            .slide-item {

                .image-wrapper {
                    position: relative;
                    display: inline-block;
                    width: calc(50% - 50px);
                    vertical-align: middle;
                    margin: 20px 25px;

                    &:before {
                        content: '';
                        width: 100%;
                        padding-bottom: 75%;
                        display: block;
                    }

                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 100%;
                        height: auto;
                        max-width: 100%;
                        max-height: 100%;
                        @include transform(translate(-50%, -50%));
                    }
                }
            }
        }
    }

    @media only screen and ($breakpoint768) {

        .customer-slider {
            margin: 28px 16px -7px;

            .slider {

                .slide-item {

                    .image-wrapper {
                        width: calc(50% - 96px);
                        margin: 7px 48px;
                    }
                }
            }
        }
    }

    @media only screen and ($breakpoint1000) {

        .customer-slider {
            margin: 17px 0 -18px;

            .slider {

                .slide-item {

                    .image-wrapper {
                        width: calc(33.333333% - 64px);
                        margin: 18px 32px;
                    }
                }
            }
        }
    }

    @media only screen and ($breakpoint1200) {

        .customer-slider {
            margin: 13px 0 -27px;

            .slider {

                .slide-item {

                    .image-wrapper {
                        width: calc(33.333333% - 100px);
                        margin: 27px 50px;
                    }
                }
            }
        }
    }

    @media only screen and ($breakpoint1700) {

        .customer-slider {
            margin: 35px 0 -35px;

            .slider {

                .slide-item {

                    .image-wrapper {
                        width: calc(33.333333% - 130px);
                        margin: 35px 65px;
                    }
                }
            }
        }
    }
</style>