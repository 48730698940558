<template>
	<div id="overflow-wrapper" class="sub">
		<onheader/>
		<nuxt/>
		<onfooter/>
    </div>
</template>

<script>
    export default {

        components: {
			Onfooter: () => import('@/components/page/footer.vue'),
			Onheader: () => import('@/components/page/header/sub.vue')
        },

        head() {
            return {
				htmlAttrs: {
                    lang: this.$i18n.locale
                }
            }
        }
    }
</script>