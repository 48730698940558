<template>
    <b-jumbotron class="bg-lightgrey">
        <b-row>
            <h2 class="text-center">{{ $t('index.offices.title') }}</h2>
            <div ref="wrapper"
                 class="offices">
                <div class="office"
                     @mouseenter="handleMouseEnter(index)"
                     v-bind:class="{ active : activeIndex === index }"
                     v-for="(office, index) in offices">
                    <figure class="image-wrapper">
                        <picture>
                            <source media="(max-width: 479px)"
                                    srcset="/img/2021-05-21_ON_fallback.png"
                                    :data-srcset="require('~/assets/img/' + office.img + '?size=480').src + ' 1x, ' + require('~/assets/img/' + office.img + '?size=960').src + ' 2x'">
                            <source media="(max-width: 800px)"
                                    srcset="/img/2021-05-21_ON_fallback.png"
                                    :data-srcset="require('~/assets/img/' + office.img + '?size=800').src + ' 1x, ' + require('~/assets/img/' + office.img + '?size=1600').src + ' 2x'">
                            <source media="(max-width: 1599px)"
                                    srcset="/img/2021-05-21_ON_fallback.png"
                                    :data-srcset="require('~/assets/img/' + office.img + '?size=450').src + ' 1x, ' + require('~/assets/img/' + office.img + '?size=900').src + ' 2x'">
                            <source srcset="/img/2021-05-21_ON_fallback.png"
                                    :data-srcset="require('~/assets/img/' + office.img + '?size=520').src + ' 1x, ' + require('~/assets/img/' + office.img + '?size=1040').src + ' 2x'">
                            <img class="lazyload"
                                 width="1040"
                                 height="780"
                                 :alt="office.place"
                                 :src="require('~/assets/img/' + office.img + '?size=20').src"
                                 :data-src="require('~/assets/img/' + office.img + '?size=520').src" />
                        </picture>
                    </figure>
                    <div class="text-wrapper">
                        <h3>{{ office.place }}</h3>
                        <p v-html="office.address"></p>
                        <p>{{ office.phone }}<br><a class="on-underline" :href="'mailto:' + office.email">{{ office.email }}</a></p>
                    </div>
                    <a :href="office.route" rel="noopener" target="_blank">
                        <span class="icon-pointer_darkgrey on-underline">{{ $t('index.offices.route') }}</span>
                    </a>
                 </div>
            </div>
            <div class="office-nav">
                <div class="office-nav-entry" @click="setActiveOffice(index)" v-bind:class="{ active : activeIndex === index }" v-for="(office, index) in offices">{{ office.place }}</div>
            </div>
        </b-row>
    </b-jumbotron>
</template>

<script>
    import { getWindowInnerWidth } from '~/assets/js/utils';

    export default {
        name: 'offices',
        data () {

            return {
                mobileView: false,
                activeIndex: 0,
                offices: [
                    {
                        place: 'Berlin Office',
                        address: 'Pfuelstr. 5, Aufgang VI<br>D-10997 Berlin',
                        phone: this.$t('index.contact.phone'),
                        email: this.$t('index.contact.email'),
                        img: 'offices/2022-10-19_SKY_offices_berlin.jpg',
                        route: 'https://www.google.com/maps/dir//SkyGate+internetworking+GmbH,+Pfuelstra%C3%9Fe+5,+10997+Berlin/@52.5027645,13.4410892,15z/data=!3m1!5s0x47a84e10502aa26d:0x669d2e9634e05920!4m8!4m7!1m0!1m5!1m1!1s0x47a84e4e5984cd65:0x82aa57a11d60f824!2m2!1d13.4409661!2d52.502877'
                    },
                    {
                        place: 'Paderborn Office',
                        address: 'Mühlenstraße 18<br>D-33098 Paderborn',
                        phone: this.$t('index.contact.phone'),
                        email: this.$t('index.contact.email'),
                        img: 'offices/2022-10-19_SKY_offices_paderborn.jpg',
                        route: 'https://www.google.com/maps/dir//M%C3%BChlenstra%C3%9Fe+18,+D-33098+Paderborn,+Deutschland/@51.7211375,8.7500508,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47ba4c96dbb4a8cf:0xc3ce0beff42dc0fd!2m2!1d8.7544389!2d51.7211309'
                    }
                ]
            }
        },

        mounted() {
          this.handleResize();
          if(('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)) {
            window.addEventListener('orientationchange', this.handleResize);
          } else {
            window.addEventListener('resize', this.handleResize);
          }
        },

        destroyed() {
          if(('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)) {
            window.removeEventListener('orientationchange', this.handleResize);
          } else {
            window.removeEventListener('resize', this.handleResize);
          }
        },

        methods: {
            handleResize() {
              if(getWindowInnerWidth() < 768) {
                if(!this.mobileView) {
                  this.mobileView = true;
                  this.activeIndex = 0;
                  this.$refs.wrapper.style.left = '-' + (100 * this.activeIndex) + '%';
                }
              } else {
                if(this.mobileView) {
                  this.mobileView = false;
                  this.activeIndex = 0;
                  this.$refs.wrapper.style.left = '';
                }
              }
            },
            setActiveOffice (index) {
                this.activeIndex = index;

                if(getWindowInnerWidth() < 768) {
                    this.$refs.wrapper.style.left = '-' + (100 * this.activeIndex) + '%';
                } else {
                    this.$refs.wrapper.style.left = '';
                }
            },
            handleMouseEnter (index) {
                if(getWindowInnerWidth() >= 768) {
                    this.activeIndex = index;
                }
            }
        }
    }
</script>

<style lang="scss">

    .offices {
        white-space: nowrap;
        margin: 30px -10px 0;
        width: calc(200% - 20px);
        position: relative;
        left: 0;
        @include transition(.3s ease all);

        .office {
            width: 50%;
            display: inline-block;
            background: $white;
            text-align: center;
            margin: 0 10px;
            @include opacity(0.5);
            @include transition(.3s ease all);

            .text-wrapper {
                padding: 25px 15px;

                a {
                    display: inline-block;
                }
            }

            > a {
                display: block;
                padding: 13px 15px 19px;
                border-top: thin solid $lightgrey;
                font-size: 16px;
                line-height: 23px;
                color: $darkgrey;
                font-family: $ff-regular;

                .icon-pointer_darkgrey {
                    display: inline-block;
                    margin-left: 20px;
                    position: relative;
                    text-decoration: none;

                    &:after {
                        content: '';
                        width: auto;
                        height: 1px;
                        position: absolute;
                        bottom: 4px;
                        left: 0;
                        right: 0;
                        background: $darkgrey;
                        @include transition(.3s ease all);
                    }

                    &:before {
                        width: 12px;
                        height: 18px;
                        position: absolute;
                        left: -20px;
                        top: 1px;
                    }
                }

                @include hover () {

                    .icon-pointer_darkgrey {

                        &:after {
                            left: 50%;
                            right: 50%;
                        }
                    }
                }
            }

            &.active {
                @include opacity(1);
            }
        }
    }

    .office-nav {
        margin-top: 40px;

        .office-nav-entry {
            display: inline-block;
            width: 50%;
            text-align: center;
            padding: 0 10px;
            font-size: 16px;
            line-height: 23px;
            color: $lightblue;
            font-family: $ff-regular;
            text-decoration: underline;
            cursor: pointer;
            @include transition(.3s ease all);

            @include hover () {
                text-decoration: none;
            }

            &.active {
                color: $darkgrey;
                pointer-events: none;
                text-decoration: none;
            }
        }
    }

    @media only screen and ($breakpoint768) {

        .offices {
            width: 100%;
            margin: 35px auto 0;
            text-align: center;
            min-height: 516px;

            .office {
                margin: 0;
                vertical-align: middle;
                position: relative;
                width: 320px;
                @include opacity(1);
                @include box-shadow(0 0 20px 0 rgba($midgrey, .2));

                .image-wrapper {
                    position: relative;

                    &:before {
                        content: '';
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: 0;
                        z-index: 1;
                        background: rgba($darkgrey, .2);
                        @include transition(.3s ease all);
                    }
                }

                &.active {
                    z-index: 1;
                    width: 352px;

                    .image-wrapper {

                        &:before {
                            background: rgba($darkgrey, 0);
                        }
                    }
                }

                .hide-on-tablet {
                    display: none;
                }
            }
        }

        .office-nav {
            display: none;
        }
    }

    @media only screen and ($breakpoint1200) {

        .offices {
            min-height: 679px;

            .office {
                width: 399px;

                &.active {
                    width: 450px;
                }

                > a {
                    font-size: 24px;
                    line-height: 30px;
                    padding: 25px 0;

                    .icon-pointer_darkgrey {
                        margin-left: -29px;

                        &:before {
                            width: 18px;
                            height: 26px;
                            left: -29px;
                        }
                    }
                }
            }
        }
    }

    @media only screen and ($breakpoint1700) {

        .offices {
            min-height: 730px;

            .office {
                width: 432px;

                &.active {
                    width: 519px;
                }
            }
        }
    }
</style>