<template>
	<div ref="overflow" id="overflow-wrapper" class="main">
		<onheader/>
		<nuxt/>
		<onfooter/>
		<client-only>
			<oncontact v-show="contactVisibility"/>
		</client-only>
    </div>
</template>

<script>
    export default {
        components: {
			Onfooter: () => import('@/components/page/footer.vue'),
			Onheader: () => import('@/components/page/header/main.vue'),
			Oncontact: () => import('@/components/page/contact.vue')
        },
        head() {
            return {
                htmlAttrs: {
                    lang: this.$i18n.locale
                }
            }
        },
		computed: {
			contactVisibility() {
				if(this.$store.state.contactFormInViewport) {
					return false;
				} else if (this.$store.state.preFooterInViewport) {
					return false;
				} else {
					return true;
				}
			}
		}
    }
</script>
