<template>
  <main>
    <div class="svg-wrapper">
      <svg width="646" height="132" viewBox="0 0 646 132" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient x1="50%" y1="0%" x2="50%" y2="99.913%" id="a"><stop stop-color="#FFF" offset="0%"/><stop stop-color="#FFF" stop-opacity=".181" offset="99.913%"/></linearGradient></defs><path d="M1069.229 131c44.99 0 52.771 27.386 52.771 64.62 0 36.994-9.484 64.38-54.229 64.38-44.988 0-52.771-27.386-52.771-64.38 0-37.234 9.484-64.62 54.229-64.62zm-112.824 0c18.104 0 31.6 5.526 35.941 8.169h0l-1.445 20.881c-6.03-2.64-21.468-8.166-33.773-8.166-21.95 0-32.317 12.012-32.317 35.312h0v17.297c0 26.423 11.576 35.074 26.531 35.074 8.684 0 16.645-2.642 19.299-3.845h0V194.79h24.343v53.662l.016.016C989.452 252.314 972.328 260 949.893 260 906.235 260 899 230.694 899 195.86c0-35.794 10.612-64.86 57.405-64.86zm-424.178-1c44.99 0 52.773 27.386 52.773 64.622 0 36.992-9.487 64.378-54.23 64.378-44.987 0-52.77-27.386-52.77-64.378 0-37.236 9.484-64.622 54.227-64.622zm238.795 4C818.343 134 828 156.389 828 195.39h0l-.016.017c0 41.166-13.525 62.593-63.014 62.593h0H729V134zM876 134v124h-25V134h25zm-227.967 0c45.781 0 56.321 20.986 56.967 57.624h0l-.013 66.351h-25.361v-70.824c-.026-25.224-13.744-32.677-32.33-32.677h0-15.943V258H606V134zm420.713 17.906c-18.24 0-27.726 11.529-27.726 35.075h0v17.054c0 23.783 8.998 35.071 27.237 35.071 18.24 0 27.723-11.528 27.723-35.071h0V186.98c0-23.783-8.995-35.075-27.234-35.075zm-537.001-1.002c-18.24 0-27.723 11.531-27.723 35.075h0v17.058c0 23.78 8.976 35.072 27.233 35.072 18.258 0 27.723-11.532 27.723-35.072h0v-17.058c0-23.784-8.995-35.075-27.233-35.075zm238.538 3.56h-15.934v83.059h9.174c27.52 0 38.389-8.668 38.389-33.224h0l.006-17.095c0-25.277-13.046-32.74-31.635-32.74h0z" transform="translate(-477 -129)" fill-rule="nonzero" stroke="url(#a)" stroke-width="2" fill="none" opacity=".7" stroke-dasharray="6"/></svg>
    </div>
    <h1 class="text-center">{{ $t('error.title') }}</h1>
    <p class="text-center">{{ $t('error.copy1') }}</p>
    <p class="text-center">{{ $t('error.copy2') }}</p>
    <NuxtLink class="btn cta ghost" :to="localePath('/')">{{ $t('error.home') }}</NuxtLink>
    <NuxtLink class="btn cta" :to="{ path: '/', hash:'#contact'}">{{ $t('error.contact') }}</NuxtLink>
  </main>
</template>

<script>
  export default {
    layout: 'empty',
    props: {
      error: {
        type: Object,
        default: null
      }
    },
    head () {
      const title = this.error.statusCode === 404 ? this.pageNotFound : this.otherError;
      return {
        title
      }
    },
    data () {
      return {
        pageNotFound: '404 Not Found',
        otherError: 'Uups! Etwas ist schief gelaufen…'
      }
    }
  }
</script>

<style scoped lang="scss">
  main {
    background: $darkblue;
    min-height: 100vh;
    text-align: center;
    padding: 60px 15px 80px;

    .svg-wrapper {
      margin: 0 auto;
      width: 90%;
      max-width: 650px;

      svg {
        width: 100%;
        height: auto;
      }
    }

    .btn {
      margin-top: 20px;
      margin-left: 10px;
      margin-right: 10px;
    }

    h1, p {
      color: $white;
    }
  }

  @media only screen and ($breakpoint375) {

    main {
      padding: 60px 30px 80px;
    }
  }

  @media only screen and ($breakpoint768) {

    main {
      padding: 60px 16px 80px;

      .btn {
        margin-top: 35px;
      }
    }
  }

  @media only screen and ($breakpoint1000) {

    main {
      padding: 100px 16px 130px;
    }
  }

  @media only screen and ($breakpoint1200) {

    main {
      padding: 100px 0 130px;

      .btn {
        margin-top: 45px;
      }
    }
  }

  @media only screen and ($breakpoint1700) {

    main {
      padding: 180px 0 220px;

      .btn {
        margin-top: 60px;
      }
    }
  }
</style>