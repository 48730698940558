<template>
    <b-jumbotron id="projects" class="bg-lightgrey">
        <b-row>
            <h2 class="text-center">{{ $t('index.projects.title') }}</h2>
            <div class="projects" ref="wrapper">
                <div class="project" @click="showOverlay(project, index)" v-for="(project, index) in projects">
                    <figure class="image-wrapper">
                        <picture>
                            <source media="(max-width: 479px)"
                                    srcset="/img/2021-05-21_ON_fallback.png"
                                    :data-srcset="require('~/assets/img/' + project.img + '?size=480').src + ' 1x, ' + require('~/assets/img/' + project.img + '?size=960').src + ' 2x'">
                            <source media="(max-width: 800px)"
                                    srcset="/img/2021-05-21_ON_fallback.png"
                                    :data-srcset="require('~/assets/img/' + project.img + '?size=800').src + ' 1x, ' + require('~/assets/img/' + project.img + '?size=1600').src + ' 2x'">
                            <source srcset="/img/2021-05-21_ON_fallback.png"
                                    :data-srcset="require('~/assets/img/' + project.img + '?size=400').src + ' 1x, ' + require('~/assets/img/' + project.img + '?size=800').src + ' 2x'">
                            <img class="lazyload"
                                 width="1500"
                                 height="1125"
                                 :alt="'Vorschau: ' + project.title"
                                 :src="require('~/assets/img/' + project.img + '?size=20').src"
                                 :data-src="require('~/assets/img/' + project.img + '?size=400').src" />
                        </picture>
                    </figure>
                    <p class="h5">{{ project.title }}<span class="icon-navigate_lightblue"></span></p>
                </div>
                <v-dialog-transition>
                    <div v-show="visible" class="projects-overlay" :key="activeProject.index">
                        <div class="overlay-content">
                            <div class="overlay-content-wrapper">
                                <div class="project-slider">
                                    <div ref="slider" class="slider">
                                        <div class="slide-item" v-for="slide in activeProject.slider">
                                            <template v-if="slide.video">
                                                <div class="video-wrapper">
                                                    <video ref="slideVideo"
                                                           id="slide-video"
                                                           data-init="0"
                                                           :data-poster="slide.poster"
                                                           src=""
                                                           :data-src="slide.video">
                                                        <p class="vjs-no-js">{{ $t('videojs.copy1') }}<a href="http://videojs.com/html5-video-support/" rel="noopener" target="_blank">{{ $t('videojs.copy2') }}</a></p>
                                                    </video>
                                                </div>
                                            </template>
                                            <template v-if="slide.img">
                                                <figure class="image-wrapper">
                                                    <picture>
                                                        <source media="(max-width: 479px)"
                                                                srcset="/img/2021-05-21_ON_fallback.png"
                                                                :data-srcset="require('~/assets/img/' + slide.img + '?size=480').src + ' 1x, ' + require('~/assets/img/' + slide.img + '?size=960').src + ' 2x'">
                                                        <source media="(max-width: 800px)"
                                                                srcset="/img/2021-05-21_ON_fallback.png"
                                                                :data-srcset="require('~/assets/img/' + slide.img + '?size=622').src + ' 1x, ' + require('~/assets/img/' + slide.img + '?size=1244').src + ' 2x'">
                                                        <source media="(max-width: 999px)"
                                                                srcset="/img/2021-05-21_ON_fallback.png"
                                                                :data-srcset="require('~/assets/img/' + slide.img + '?size=750').src + ' 1x, ' + require('~/assets/img/' + slide.img + '?size=1500').src + ' 2x'">
                                                        <source media="(max-width: 1199px)"
                                                                srcset="/img/2021-05-21_ON_fallback.png"
                                                                :data-srcset="require('~/assets/img/' + slide.img + '?size=495').src + ' 1x, ' + require('~/assets/img/' + slide.img + '?size=990').src + ' 2x'">
                                                        <source media="(max-width: 1599px)"
                                                                srcset="/img/2021-05-21_ON_fallback.png"
                                                                :data-srcset="require('~/assets/img/' + slide.img + '?size=515').src + ' 1x, ' + require('~/assets/img/' + slide.img + '?size=1030').src + ' 2x'">
                                                        <source srcset="/img/2021-05-21_ON_fallback.png"
                                                                :data-srcset="require('~/assets/img/' + slide.img + '?size=688').src + ' 1x, ' + require('~/assets/img/' + slide.img + '?size=1376').src + ' 2x'">
                                                        <img class="lazyload"
                                                             width="1500"
                                                             height="1125"
                                                             :alt="slide.alt"
                                                             :src="require('~/assets/img/' + slide.img + '?size=20').src"
                                                             :data-src="require('~/assets/img/' + slide.img + '?size=688').src" />
                                                    </picture>
                                                </figure>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-wrapper">
                                    <h3>{{ activeProject.title }}</h3>
                                    <p class="h5">{{ this.$t('index.projects.customer') }}</p>
                                    <p>{{ activeProject.customer }}</p>
                                    <p class="h5">{{ this.$t('index.projects.task') }}</p>
                                    <p>{{ activeProject.task }}</p>
                                    <p class="h5">{{ this.$t('index.projects.services') }}</p>
                                    <ul class="services">
                                        <li v-for="service in activeProject.services">{{ service }}</li>
                                    </ul>
                                    <p class="h5 stack-collapse" @click="showStack = !showStack">{{ this.$t('index.projects.stacks') }}<span v-bind:class="{'opened' : showStack}" class="icon-arrow_lightblue"></span></p>
                                    <v-expand-transition>
                                        <ul v-show="showStack" class="stack">
                                            <li v-for="stack in activeProject.stacks">{{ stack.title }}
                                                <ul class="stack-items">
                                                    <li v-for="item in stack.items">{{ item }}</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </v-expand-transition>
                                    <div @click="hideOverlay" class="icon-close_white"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-dialog-transition>
            </div>
        </b-row>
    </b-jumbotron>
</template>

<script>
    import { elementExisits, stopPageScroll, enablePageScroll } from '~/assets/js/utils';

    export default {
        name: 'projects',
        data () {

            return {
                projects: [
                    {
                        title: this.$t('index.projects.pad.title'),
                        customer: this.$t('index.projects.pad.customer'),
                        task: this.$t('index.projects.pad.task'),
                        services: [
                          this.$t('index.projects.service.concept'),
                          this.$t('index.projects.service.ux'),
                          this.$t('index.projects.service.ui'),
                          this.$t('index.projects.service.art'),
                          this.$t('index.projects.service.frontend'),
                          this.$t('index.projects.service.t3'),
                          this.$t('index.projects.service.cmp'),
                          this.$t('index.projects.service.hosting')
                        ],
                        stacks: [
                          {
                            title: this.$t('index.projects.stack.fe'),
                            items: ['Vue', 'CSS3', 'HTML5', 'SASS', 'Webpack']
                          },
                          {
                            title: this.$t('index.projects.stack.be'),
                            items: ['TYPO3 v10', 'composer', 'Symfony', 'mariadb', 'REST-APIs']
                          },
                          {
                            title: this.$t('index.projects.stack.deploy'),
                            items: ['Gitlab-CI/CD', 'Docker', 'Composer', 'Node.js', 'Sentry']
                          },
                          {
                            title: this.$t('index.projects.stack.host'),
                            items: ['apache', 'mysql', 'php-fpm']
                          },
                        ],
                        img: 'projects/pad/2020-11-09_ON_project_pad_teaser.jpg',
                        slider: [
                            {
                                img: 'projects/pad/2021-04-30_ON_project_pad_1.jpg',
                                alt: 'Paderhalle Startseite Responsive'
                            },
                            {
                                img: 'projects/pad/2021-04-30_ON_project_pad_2.jpg',
                                alt: 'Paderhalle Veranstaltungen'
                            },
                            {
                                img: 'projects/pad/2021-04-30_ON_project_pad_3.jpg',
                                alt: 'Paderhalle Karten und Gutscheine'
                            },
                            {
                                img: 'projects/pad/2021-04-30_ON_project_pad_4.jpg',
                                alt: 'Paderhalle Showroom'
                            },
                            {
                                img: 'projects/pad/2021-04-30_ON_project_pad_5.jpg',
                                alt: 'Paderhalle mobile Darstellung'
                            }
                        ]
                    },
                    {
                        title: this.$t('index.projects.thue.title'),
                        customer: this.$t('index.projects.thue.customer'),
                        task: this.$t('index.projects.thue.task'),
                        services: [
                            this.$t('index.projects.service.concept'),
                            this.$t('index.projects.service.ux'),
                            this.$t('index.projects.service.ui'),
                            this.$t('index.projects.service.art'),
                            this.$t('index.projects.service.frontend'),
                            this.$t('index.projects.service.t3'),
                            this.$t('index.projects.service.cmp'),
                            this.$t('index.projects.service.content')
                        ],
                        stacks: [
                            {
                              title: this.$t('index.projects.stack.fe'),
                              items: ['Vue', 'CSS3', 'HTML5', 'SASS', 'Webpack']
                            },
                            {
                              title: this.$t('index.projects.stack.be'),
                              items: ['TYPO3 v11']
                            },
                            {
                              title: this.$t('index.projects.stack.deploy'),
                              items: ['Gitlab-CI/CD', 'Docker', 'Composer', 'Node.js']
                            }
                        ],
                        img: 'projects/thue/2022-03-17_ON_project_thue_teaser.jpg',
                        slider: [
                            {
                                img: 'projects/thue/2022-03-17_ON_project_thue_1.jpg',
                                alt: 'Thüringer Bachwochen Startseite Responsive'
                            },
                            {
                                img: 'projects/thue/2022-03-17_ON_project_thue_2.jpg',
                                alt: 'Thüringer Bachwochen Veranstaltungen'
                            },
                            {
                                img: 'projects/thue/2022-03-17_ON_project_thue_3.jpg',
                                alt: 'Thüringer Bachwochen Veranstaltung im Detail'
                            },
                            {
                                img: 'projects/thue/2022-03-17_ON_project_thue_4.jpg',
                                alt: 'Thüringer Bachwochen Live Konzerte'
                            }
                        ]
                    },
                    {
                        title: this.$t('index.projects.orch.title'),
                        customer: this.$t('index.projects.orch.customer'),
                        task: this.$t('index.projects.orch.task'),
                        services: [
                            this.$t('index.projects.service.concept'),
                            this.$t('index.projects.service.ux'),
                            this.$t('index.projects.service.ui'),
                            this.$t('index.projects.service.art'),
                            this.$t('index.projects.service.animation'),
                            this.$t('index.projects.service.patternlab')
                        ],
                        stacks: [
                            {
                              title: this.$t('index.projects.stack.fe'),
                              items: ['Vue', 'CSS3', 'HTML5', 'SASS', 'Webpack', 'Patternlab']
                            },
                            {
                              title: this.$t('index.projects.stack.deploy'),
                              items: ['Gitlab-CI/CD', 'Docker', 'Composer', 'Node.js', 'Sentry']
                            }
                        ],
                        img: 'projects/orch/2020-11-09_ON_project_orch_teaser.jpg',
                        slider: [
                            {
                                video: '/video/projects/orch/2021-04-28_ON_project_orch.mp4',
                                poster: '/video/projects/orch/2020-11-09_ON_project_orch_poster.jpg'
                            },
                            {
                                img: 'projects/orch/2021-04-30_ON_project_orch_1.jpg',
                                alt: 'Orchestral Tools Startseite Responsive'
                            },
                            {
                                img: 'projects/orch/2021-04-30_ON_project_orch_2.jpg',
                                alt: 'Orchestral Tools Suchfunktion'
                            },
                            {
                                img: 'projects/orch/2021-04-30_ON_project_orch_3.jpg',
                                alt: 'Orchestral Tools Bundles Übersicht'
                            },
                            {
                                img: 'projects/orch/2021-04-30_ON_project_orch_4.jpg',
                                alt: 'Orchestral Tools Bundle'
                            },
                            {
                                img: 'projects/orch/2021-04-30_ON_project_orch_5.jpg',
                                alt: 'Orchestral Tools Downloads'
                            },
                            {
                                img: 'projects/orch/2021-04-30_ON_project_orch_6.jpg',
                                alt: 'Orchestral Tools Single Instruments Audio Demos'
                            },
                            {
                                img: 'projects/orch/2021-04-30_ON_project_orch_7.jpg',
                                alt: 'Orchestral Tools Instruments kaufen'
                            },
                            {
                                img: 'projects/orch/2021-04-30_ON_project_orch_8.jpg',
                                alt: 'Orchestral Tools Stage Element Design Konzept'
                            }
                        ]
                    },
                    {
                        title: this.$t('index.projects.kies.title'),
                        customer: this.$t('index.projects.kies.customer'),
                        task: this.$t('index.projects.kies.task'),
                        services: [
                            this.$t('index.projects.service.concept'),
                            this.$t('index.projects.service.ux'),
                            this.$t('index.projects.service.ui'),
                            this.$t('index.projects.service.art'),
                            this.$t('index.projects.service.frontend'),
                            this.$t('index.projects.service.vue'),
                            this.$t('index.projects.service.t3upgrade'),
                            this.$t('index.projects.service.cmp'),
                            this.$t('index.projects.service.awscluster'),
                            this.$t('index.projects.service.marketing')
                        ],
                        stacks: [
                            {
                              title: this.$t('index.projects.stack.fe'),
                              items: ['CSS3', 'HTML5', 'SASS', 'Webpack', 'Vue', 'Storybook']
                            },
                            {
                              title: this.$t('index.projects.stack.be'),
                              items: ['TYPO3 v10', 'composer', 'Symfony', 'mariadb', 'REST-APIs']
                            },
                            {
                              title: this.$t('index.projects.stack.deploy'),
                              items: ['Gitlab-CI/CD', 'Docker', 'Composer', 'Node.js', 'Sentry', 'Ansible', 'Terraform']
                            },
                            {
                              title: this.$t('index.projects.stack.host'),
                              items: ['AWS Cloud Hosting', 'TYPO3 Cluster', 'Cloudfront', 'EC2', 'RDS', 'ElastiCache', 'Route53', 'EFS', 'VPC', 'haproxy']
                            },
                            {
                              title: this.$t('index.projects.stack.monitoring'),
                              items: ['Sentry', 'Graylog']
                            },
                        ],
                        img: 'projects/kies/2021-05-10_ON_project_kies_teaser.jpg',
                        slider: [
                            {
                                img: 'projects/kies/2021-04-30_ON_project_kies_1.jpg',
                                alt: 'Kieser Training Startseite Responsive'
                            },
                            {
                                img: 'projects/kies/2021-04-30_ON_project_kies_2.jpg',
                                alt: 'Kieser Training Inhalte'
                            },
                            {
                                img: 'projects/kies/2021-04-30_ON_project_kies_3.jpg',
                                alt: 'Kieser Training Studio Seiten'
                            },
                            {
                                img: 'projects/kies/2021-04-30_ON_project_kies_4.jpg',
                                alt: 'Kieser Training Studio Login Web Application Inhouse Liste'
                            },
                            {
                                img: 'projects/kies/2021-04-30_ON_project_kies_5.jpg',
                                alt: 'Kieser Training Studio Login Web Application Traningstermine'
                            },
                            {
                                img: 'projects/kies/2021-04-30_ON_project_kies_6.jpg',
                                alt: 'Kieser Training Kundenbereich Web Application Traningstermine'
                            },
                            {
                                img: 'projects/kies/2021-04-30_ON_project_kies_7.jpg',
                                alt: 'Kieser Training Kundenbereich Web Application'
                            },
                            {
                                img: 'projects/kies/2021-04-30_ON_project_kies_8.jpg',
                                alt: 'Kieser Training Kundenbereich Web Application Übersicht Traningstermine'
                            },
                            {
                                img: 'projects/kies/2021-04-30_ON_project_kies_9.jpg',
                                alt: 'Kieser Training Display Ads'
                            }
                        ]
                    },
                    {
                        title: this.$t('index.projects.bph.title'),
                        customer: this.$t('index.projects.bph.customer'),
                        task: this.$t('index.projects.bph.task'),
                        services: [
                            this.$t('index.projects.service.concept'),
                            this.$t('index.projects.service.ux'),
                            this.$t('index.projects.service.ui'),
                            this.$t('index.projects.service.frontend'),
                            this.$t('index.projects.service.t3upgrade'),
                            this.$t('index.projects.service.aws')
                        ],
                        stacks: [
                            {
                              title: this.$t('index.projects.stack.fe'),
                              items: ['CSS3', 'HTML5', 'SASS', 'Webpack']
                            },
                            {
                              title: this.$t('index.projects.stack.be'),
                              items: ['TYPO3 v10', 'composer', 'Symfony', 'mariadb', 'REST-APIs']
                            },
                            {
                              title: this.$t('index.projects.stack.deploy'),
                              items: ['Composer', 'Node.js']
                            },
                            {
                              title: this.$t('index.projects.stack.host'),
                              items: ['AWS Cloud Hosting', 'Cloudfront', 'EC2', 'RDS', 'Route53', 'VPC']
                            }
                        ],
                        img: 'projects/bph/2020-11-09_ON_project_bph_teaser.jpg',
                        slider: [
                            {
                                img: 'projects/bph/2021-04-30_ON_project_bph_1.jpg',
                                alt: 'Berliner Philharmoniker Startseite Responsive'
                            },
                            {
                                img: 'projects/bph/2021-04-30_ON_project_bph_2.jpg',
                                alt: 'Berliner Philharmoniker Konzertkalender'
                            },
                            {
                                img: 'projects/bph/2021-04-30_ON_project_bph_3.jpg',
                                alt: 'Berliner Philharmoniker Konzert Informationen'
                            },
                            {
                                img: 'projects/bph/2021-04-30_ON_project_bph_4.jpg',
                                alt: 'Berliner Philharmoniker Projekte'
                            },
                            {
                                img: 'projects/bph/2021-04-30_ON_project_bph_5.jpg',
                                alt: 'Berliner Philharmoniker Mediathek'
                            }
                        ]
                    },
                    {
                        title: this.$t('index.projects.sky.title'),
                        customer: this.$t('index.projects.sky.customer'),
                        task: this.$t('index.projects.sky.task'),
                        services: [
                            this.$t('index.projects.service.concept'),
                            this.$t('index.projects.service.ux'),
                            this.$t('index.projects.service.ui'),
                            this.$t('index.projects.service.frontend'),
                            this.$t('index.projects.service.patternlab'),
                            this.$t('index.projects.service.storybook')
                        ],
                        stacks: [
                            {
                              title: this.$t('index.projects.stack.fe'),
                              items: ['Vue', 'Storybook', 'Patternlab', 'CSS3', 'HTML5', 'SASS', 'Webpack', 'Vuetify', 'Bootstrap', 'Ansible']
                            },
                            {
                              title: this.$t('index.projects.stack.deploy'),
                              items: ['Gitlab-CI/CD', 'Docker', 'Composer', 'Node.js']
                            }
                        ],
                        img: 'projects/sky/2021-05-03_ON_project_sky_teaser.jpg',
                        slider: [
                          {
                            img: 'projects/sky/2021-05-03_ON_project_sky_teaser.jpg',
                            alt: 'Standort SkyGate'
                          }
                        ]
                    }
                ],
                activeProject: {
                  title: 'Projekt'
                },
                activeProjectSlider: null,
                visible: false,
                module: null,
                video: null,
                showStack: false
            }
        },
        mounted () {
            let _scope = this;

            document.addEventListener('keydown', (event) => {
                if (event.keyCode === 27) {
                  if(_scope.visible) {
                    _scope.hideOverlay();
                  }
                }
            });

            window.onbeforeunload = function(e) {
              if(_scope.visible) {
                return "Um das Overlay zu schließen, klicken Sie bitte auf das X oben rechts.";
              }
            };

            this.$nextTick(() => {
                this.module = require('tiny-slider/src/tiny-slider.module');
            });
        },

        methods: {
            showOverlay(project, index) {
                this.activeProject = project;
                this.activeProject.index = index;
                this.visible = true;

                if(this.activeProjectSlider !== null) {
                    this.activeProjectSlider.destroy();
                    this.activeProjectSlider = null;
                }

                if(this.video !== null) {
                    this.video.dispose();
                    this.video = null;
                }

                // prevent overlap
                setTimeout(function () {
                  stopPageScroll('has-overlay');
                }, 500);
            },

            initVideo () {
                let videojs = require('video.js/dist/video.min');

                this.video = videojs('slide-video', {
                    preload: 'none',
                    loop: true,
                    poster: this.$refs.slideVideo[0].getAttribute('data-poster'),
                    controls: false,
                    muted: true,
                    autoplay: true,
                    playsinline: true,
                    sourceOrder: true,
                    language: this.$i18n.locale,
                    sources: [{
                        src: this.$refs.slideVideo[0].getAttribute('data-src'),
                        type: 'video/mp4'
                    }]
                });
            },

            hideOverlay() {
                let _scope = this;

                enablePageScroll('has-overlay');

                // prevent overlap
                setTimeout(function () {
                  _scope.activeProject = {};
                  _scope.visible = false;
                }, 300);
            }
        },

        updated: function () {
            let _scope = this;

            if (elementExisits(this.$refs.slider) && this.activeProjectSlider === null) {
                this.activeProjectSlider = this.module.tns({
                    container: this.$refs.slider,
                    items: 1,
                    slideBy: 1,
                    arrowKeys: true,
                    loop: false,
                    nav: false,
                    edgePadding: 50,
                    controlsPosition: 'bottom',
                    controlsText: ["zurück", "weiter"],
                    preventScrollOnTouch: 'auto',
                    responsive: {
                        768: {
                            edgePadding: 65,
                        },
                        1000: {
                            edgePadding: 0,
                        }
                    }
                });

                if(elementExisits(this.$refs.slideVideo) && this.$refs.slideVideo.length) {
                    this.initVideo();
                }

                this.activeProjectSlider.events.on('indexChanged', function (e) {
                    if(_scope.activeProjectSlider.getInfo().index === 0) {
                        if(_scope.video !== null && _scope.video.paused()) {
                            _scope.video.play();
                        }
                    } else {
                        if(_scope.video !== null && !_scope.video.paused()) {
                            _scope.video.pause();
                        }
                    }
                });
            }
        }
    }
</script>

<style lang="scss">

    .projects {
        margin-top: 30px;

        .project {
            cursor: pointer;
            touch-action: manipulation;

            .image-wrapper {
                pointer-events: none;
                @include opacity(1);
                @include transition(.3s ease all);
            }

            .h5 {
                margin-top: 15px;
                position: relative;

                .icon-navigate_lightblue {
                    position: absolute;
                    right: 0;
                    top: 0;
                    @include transition(.3s ease all);

                    &:before {
                        width: 22px;
                        height: 22px;
                        @include transition(.3s ease all);
                    }
                }
            }

            @include hover () {

                .image-wrapper {
                    @include opacity(.85);
                }

                .h5 {

                    .icon-navigate_lightblue {
                        @include animation(arrowAnimation 2s infinite);

                        &:before {
                            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='21px' height='19px' viewBox='0 0 21 19' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3ECombined-Shape%3C/title%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='BLOCKS-/-375-/-projekte' transform='translate(-324.000000, -421.000000)' fill='%2382CFF4' fill-rule='nonzero'%3E%3Cg id='paderhalle' transform='translate(30.000000, 140.000000)'%3E%3Cpath d='M296.119192,289.472414 L303.227672,282.13771 C303.475833,281.870816 303.475833,281.447932 303.227672,281.19203 C302.968452,280.93599 302.558582,280.93599 302.310421,281.19203 L294.186121,289.574292 C293.93796,289.841743 293.93796,290.264627 294.186121,290.520668 L302.310421,298.90293 C302.428972,299.036655 302.601741,299.103448 302.76345,299.103448 C302.925294,299.103448 303.087543,299.036655 303.216613,298.90293 C303.464774,298.636035 303.464774,298.213151 303.227672,297.967965 L296.186719,290.703448 L314.384483,290.703448 C314.724424,290.703448 315,290.427872 315,290.087931 C315,289.74799 314.724424,289.472414 314.384483,289.472414 L314.384483,289.472414 L296.119192,289.472414 Z' id='Combined-Shape' transform='translate(304.500000, 290.051724) scale(-1, 1) translate(-304.500000, -290.051724) '%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                        }
                    }
                }
            }

            & + .project {
                margin-top: 40px;
            }
        }

        .projects-overlay {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 12;
            background: $white;
            @include transition(.4s ease all);

            .overlay-content {
                height: 100%;
                overflow-y: auto;
                overflow-x: hidden;
                padding: 50px 15px 120px;

                h3 + div {
                    margin-top: 10px;
                }

                .h5.stack-collapse {
                    cursor: pointer;

                    .icon-arrow_lightblue {
                        @include transform(rotate(-90deg));
                        @include transition(.3s ease all);
                        position: relative;
                        left: 6px;
                        top: 7px;

                        &:before {
                            width: 30px;
                            height: 30px;
                        }

                        &.opened {
                            @include transform(rotate(90deg));
                        }
                    }

                    @include hover () {
                        text-decoration: underline;
                    }
                }

                ul {

                    &.services {

                        li {
                            display: inline-block;
                            width: 50%;
                            vertical-align: top;
                        }
                    }

                    &.stack {

                        li {

                            & + li {
                                margin-top: 20px;
                            }
                        }

                        .stack-items {
                            margin-top: 7px;

                            li {
                                display: inline-block;
                                background: $lightgrey;
                                padding: 10px 15px;
                                margin: 2px;
                                font-size: 14px;
                                line-height: 14px;
                                text-align: center;

                                &:before {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                .icon-close_white {
                    position: absolute;
                    left: 50%;
                    bottom: 47px;
                    margin-left: -11px;
                    cursor: pointer;
                    width: 40px;
                    height: 40px;
                    background: $lightblue;
                    @include border-radius(50%);
                    @include transition(.3s ease all);
                    @include transform(scale(1));

                    &:before {
                        width: 20px;
                        height: 20px;
                        position: relative;
                        top: 10px;
                        left: 10px;
                        display: inline-block;
                    }

                    @include hover () {
                        @include transform(scale(1.4));
                    }
                }

                .text-wrapper {
                    margin-top: 55px;
                }
            }
        }
    }

    @include keyframes(arrowAnimation) {
        0%   { right: 0; }
        30%  { right: 0; }
        40%  { right: 5px; }
        50%  { right: 0; }
        60%  { right: 5px; }
        70%  { right: 0; }
        100% { right: 0; }
    }

    @media only screen and ($breakpoint375) {

        .projects {

            .projects-overlay {

                .overlay-content {
                    padding: 50px 30px 120px;
                }
            }
        }
    }

    @media only screen and ($breakpoint768) {

        .projects {
            margin-top: 0;
            margin-left: -8px;
            margin-right: -8px;

            .project {
                margin-top: 35px;
                display: inline-block;
                vertical-align: top;
                width: 50%;
                padding: 0 8px;

                .h5 {

                    .icon-navigate_lightblue {
                        position: relative;
                        right: auto;
                        left: 20px;
                        top: 4px;
                    }
                }

                & + .project {
                    margin-top: 35px;
                }
            }

            .projects-overlay {

                .overlay-content {
                    padding: 75px 64px 145px;
                }
            }
        }

        @include keyframes(arrowAnimation) {
            0%   { left: 20px; }
            30%  { left: 20px; }
            40%  { left: 25px; }
            50%  { left: 20px; }
            60%  { left: 25px; }
            70%  { left: 20px; }
            100% { left: 20px; }
        }
    }

    @media only screen and ($breakpoint1000) {

        .projects {
            position: relative;

            .project {
                width: 33.333333%;
                padding: 0 4px;
            }

            .projects-overlay {

                .overlay-content {
                    padding: 32px;

                    .text-wrapper {
                        margin-top: 0;
                        width: 55%;
                        display: inline-block;
                        vertical-align: top;
                        padding-left: 16px;
                    }

                    .icon-close_white {
                        left: auto;
                        right: 30px;
                        top: 30px;
                        @include transform(translate(0, 0));
                        background: rgba($white, .8);

                        &:before {
                            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='22px' height='21px' viewBox='0 0 22 21' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicon / close%3C/title%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='BLOCKS-/-375-/-projekte-overlay' transform='translate(-176.000000, -558.000000)' fill='%23008ED6'%3E%3Cg id='Group' transform='translate(173.000000, 554.000000)'%3E%3Cg id='close' transform='translate(3.294118, 4.027778)'%3E%3Cpath d='M21.0891121,0.315602659 C21.5193156,0.736406204 21.5193156,1.41863392 21.0891121,1.83970052 L12.264,10.472 L21.0891121,19.1044809 C21.5193156,19.5252845 21.5193156,20.2075122 21.0891121,20.6283157 C20.8732037,20.8381915 20.5914204,20.9444444 20.3109816,20.9444444 C20.0289294,20.9444444 19.7471461,20.8395065 19.5325822,20.6283157 L10.706,11.995 L1.88079583,20.6283157 C1.66488745,20.8381915 1.38283531,20.9444444 1.10105203,20.9444444 C0.818999899,20.9444444 0.536947763,20.8395065 0.322652606,20.6283157 C-0.107550869,20.2075122 -0.107550869,19.5252845 0.322652606,19.1044809 L9.148,10.471 L0.322652606,1.83970052 C-0.107550869,1.41863392 -0.107550869,0.736406204 0.322652606,0.315602659 C0.75285608,-0.105200886 1.4503235,-0.105200886 1.88079583,0.315602659 L10.706,8.948 L19.5312377,0.315602659 C19.9614412,-0.105200886 20.6589086,-0.105200886 21.0891121,0.315602659 Z' id='Combined-Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                        }
                    }
                }
            }
        }
    }

    @media only screen and ($breakpoint1200) {

        .projects {

            .projects-overlay {

                .overlay-content {
                    padding: 55px 50px;

                    .overlay-content-wrapper {
                        max-width: 1200px;
                        margin: 0 auto;
                    }

                    .icon-close_white {
                        width: 50px;
                        height: 50px;
                        right: 20px;
                        top: 20px;

                        &:before {
                            width: 36px;
                            height: 36px;
                            top: 7px;
                            left: 7px;
                        }
                    }

                    ul {

                        &.stack {

                            .stack-items {

                                li {
                                    font-size: 18px;
                                    line-height: 18px;
                                    padding: 13px 20px;
                                }
                            }
                        }
                    }

                    .text-wrapper {
                        padding-left: 25px;
                    }
                }
            }
        }
    }

    @media only screen and ($breakpoint1700) {

        .projects {

            .projects-overlay {

                .overlay-content {
                    padding: 90px 64px;

                    .overlay-content-wrapper {
                        max-width: 1600px;
                    }

                    .text-wrapper {
                        padding-left: 32px;
                    }
                }
            }
        }
    }
</style>