<template>
    <main>
        <b-jumbotron class="bg-white">
            <b-row>
              <h1><span>Impressum</span></h1>
              <h3>SkyGate internetworking GmbH</h3>
              <p>Pfuelstr. 5<br>Aufgang VI<br>10997 Berlin</p>
              <p>T: +49 30 611 03 8-0<br>F: +49 30 612 80 465<br>E-Mail: <a href="mailto:skygate@skygate.de">skygate@skygate.de</a></p>
              <h3>Geschäftsführung:</h3>
              <p>Dipl.-Inf. Stephan Jensen, Sven Liebchen, Philipp Heine</p>
              <h3>Handelsregister:</h3>
              <p>Berlin Charlottenburg HRB 87258</p>
              <h3>Umsatzsteuer-Identifikationsnummer:</h3>
              <p>DE 813566910</p>
            </b-row>
        </b-jumbotron>
        <b-jumbotron class="bg-lightgrey">
            <b-row>
              <h2>Rechtliche Hinweise</h2>
              <h3>Urheberrecht</h3>
              <p>Copyright © 2022 SkyGate internetworking GmbH. Alle Rechte vorbehalten. Die Inhalte dieser Website sind urheberrechtlich geschützt. Ohne schriftliche Genehmigung seitens der SkyGate internetworking GmbH darf der Inhalt dieser Seite in keiner Form reproduziert oder unter Verwendung elektronischer Systeme verarbeitet, vervielfältigt oder verbreitet werden.</p>
              <h3>Links zu externen Websites</h3>
              <p>Für Internetseiten Dritter, auf welche die SkyGate intetnetworking GmbH durch Hyperlink verweist, tragen die jeweiligen Anbieter die Verantwortung. Die SkyGate internetworking GmbH ist für den Inhalt solcher Seiten Dritter nicht verantwortlich.</p>
              <h3>Links auf diese Website</h3>
              <p>Desweiteren kann die Web-Seite der SkyGate internetworking GmbH ohne deren Wissen von einer anderen Seite mittels Hyperlink verlinkt worden sein. Die SkyGate internetworking GmbH übernimmt keine Verantwortung für Darstellung, Inhalt oder Funktion dieser Websites.</p>
              <h3>Haftungsausschluss</h3>
              <p>Diese Website bildet einen temporären Stand eines kontinuierlichen Pflegeprozesses ab. Obwohl bei der Zusammenstellung der auf unseren Webseiten enthaltenen Informationen größte Sorgfalt angewandt wurde, kann SkyGate internetworking GmbH für die Aktualität, Richtigkeit oder Vollständigkeit keine Gewähr übernehmen.</p>
              <p>Haftungsansprüche gegen die SkyGate internetworking GmbH, die sich auf Schäden materieller oder ideeller Art beziehen, welche durch die Nutzung oder Nichtnutzung der dargebotenen Informationen oder durch fehlerhafte und unvollständige Informationen verursacht wurden, sind grundsätzlich ausgeschlossen.</p>
              <p>Wir behalten uns Änderungen der Informationen auf diesem Server ohne vorherige Ankündigung vor. Alle Angebote sind freibleibend und unverbindlich.</p>
              <h3>Marken- und Warenzeichen</h3>
              <p>Alle innerhalb dieser Website genannten Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichnungsrechts und gegebenenfalls den Besitzrechten der jeweiligen eingetragenen Eigentümer.</p>
              <h3>Rechtswirksamkeit dieses Haftungsausschlusses</h3>
              <p>Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.</p>
              <h3>Datenschutzerklärung</h3>
              <p>Siehe <NuxtLink :to="localePath('datenschutz')">{{ $t('footer.data') }}</NuxtLink></p>
            </b-row>
        </b-jumbotron>
    </main>
</template>

<script>
  export default {
      layout: 'sub',
      head () {
          return {
              title: 'Impressum SkyGate – Digitale Markenkraft',
              meta: [
                  { name: 'description', content: 'Pflichtangaben zum Impressum der SkyGate internetworking GmbH' },
                  { property: 'og:title', content: 'Impressum SkyGate – Digitale Markenkraft' },
                  { property: 'og:description', content: 'Pflichtangaben zum Impressum der SkyGate internetworking GmbH' },
              ]
          }
      },
      nuxtI18n: {
          paths: {
              en: '/legal-notice/'
          }
      },
        mounted() {
          window.onload = function () {
            if (window.usercentrics) {
              window.usercentrics.updateBannerIsVisible(false);
            }
          };
        }
  }
</script>