<template>
    <b-jumbotron id="jobs" class="bg-lightgrey">
        <b-row>
            <h2 class="text-center">{{ $t('index.jobs.title') }}</h2>
            <p class="text-center">{{ $t('index.jobs.copy') }}</p>
            <p class="text-center">{{ $t('index.jobs.nojobs') }}</p>
        </b-row>
    </b-jumbotron>
</template>

<script>
    export default {
        name: 'jobs'
    }
</script>

<style lang="scss">

    .jobs {
        border-top: thin solid $midgrey;
        margin-top: 30px;
        max-width: 345px;
        margin-left: auto;
        margin-right: auto;

        .job {
            text-align: center;
            border-bottom: thin solid $midgrey;
            padding: 15px 0;
            position: relative;

            &:after {
                content: '';
                background: rgba($lightwhiteblue, 0);
                @include transition(.3s ease all);
                position: absolute;
                left: 0;
                right: 0;
                top: 10px;
                bottom: 10px;
            }

            .icon-wrapper {
                display: none;
            }

            .h5 {
                color: $lightblue;
                text-decoration: underline;
                margin-top: 0;

                span {
                    font-size: 16px;
                    line-height: 23px;
                    color: $lightblue;
                    display: block;
                    font-family: $ff-bold;
                }

                & + p {
                    margin-top: 5px;
                }
            }

            a {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                font-size: 0;
                line-height: 0;
                color: $darkgrey;
            }

            @include hover () {

                .h5 {
                    text-decoration: none;
                }

                &:after {
                    background: rgba($lightwhiteblue, .18);
                }
            }
        }
    }

    @media only screen and ($breakpoint768) {

        .jobs {
            max-width: 608px;
            margin-top: 35px;

            .job {
                text-align: left;
                position: relative;
                padding: 25px 0 25px 145px;

                .icon-wrapper {
                    display: block;
                    position: absolute;
                    left: 30px;
                    top: 50%;
                    width: 95px;
                    @include transform(translate(0, -50%));

                    svg {
                        width: 100%;
                        height: auto;
                        max-height: 67px;
                    }
                }

                .h5 {
                    font-size: 24px;
                    line-height: 30px;

                    span {
                        font-family: $ff-semibold;
                        font-size: 24px;
                        line-height: 30px;
                        display: inline;
                    }

                    & + p {
                        margin-top: 3px;
                    }
                }

                p {
                    font-size: 18px;
                    line-height: 23px;
                }
            }
        }
    }

    @media only screen and ($breakpoint1200) {

        .jobs {
            max-width: 715px;
            margin-top: 40px;

            .job {
                padding: 35px 0 35px 195px;

                .icon-wrapper {
                    left: 35px;
                    width: 125px;

                    svg {
                        max-height: 88px;
                    }
                }

                .h5 {
                    font-size: 28px;
                    line-height: 34px;

                    span {
                        font-size: 28px;
                        line-height: 34px;
                    }

                    & + p {
                        margin-top: 1px;
                    }
                }

                p {
                    font-size: 20px;
                    line-height: 34px;
                }
            }
        }
    }


    @media only screen and ($breakpoint1200) {

        .jobs {
            margin-top: 70px;
        }
    }
</style>