<template>
    <b-jumbotron id="intro" class="bg-white">
        <b-row>
            <h1 class="text-center">{{ $t('index.introduction.title') }}</h1>
            <p class="big text-center">{{ $t('index.introduction.txt1') }}</p>
            <p class="big text-center">{{ $t('index.introduction.txt2') }}</p>
        </b-row>
    </b-jumbotron>
</template>

<script>
    export default {
        name: 'introduction'
    }
</script>
