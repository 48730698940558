<template>
    <b-jumbotron class="bg-blue">
        <b-row>
            <h2 class="text-center">{{ $t('index.team.title') }}</h2>
            <div class="team">
                    <div ref="slider" class="slider">
                        <div class="slide-item" v-for="(item) in team">
                            <figure class="image-wrapper">
                                <picture>
                                    <source media="(max-width: 479px)"
                                            srcset="/img/2021-05-21_ON_fallback.png"
                                            :data-srcset="require('~/assets/img/' + item.img + '?size=480').src + ' 1x, ' + require('~/assets/img/' + item.img + '?size=960').src + ' 2x'">
                                    <source media="(max-width: 800px)"
                                            srcset="/img/2021-05-21_ON_fallback.png"
                                            :data-srcset="require('~/assets/img/' + item.img + '?size=800').src + ' 1x, ' + require('~/assets/img/' + item.img + '?size=1600').src + ' 2x'">
                                    <source srcset="/img/2021-05-21_ON_fallback.png"
                                            :data-srcset="require('~/assets/img/' + item.img + '?size=400').src + ' 1x, ' + require('~/assets/img/' + item.img + '?size=800').src + ' 2x'">
                                    <img class="lazyload"
                                         :alt="item.name"
                                         width="650"
                                         height="650"
                                         :src="require('~/assets/img/' + item.img + '?size=20').src"
                                         :data-src="require('~/assets/img/' + item.img + '?size=400').src" />
                                </picture>
                            </figure>
                            <p class="h5 ff-bold text-center">{{ item.name }}</p>
                            <p class="text-center">{{ item.position }}<template v-if="item.additional"><br>{{ item.additional }}</template></p>
                        </div>
                    </div>
            </div>
        </b-row>
    </b-jumbotron>
</template>

<script>
    export default {
        name: 'team',
        data() {
            return {
                team: [
                    {
                        name: 'Sven Liebchen',
                        img: 'team/2020-11-17_ON_team_sven-liebchen.jpg',
                        position: this.$t('index.team.liebchen1'),
                        additional: this.$t('index.team.liebchen2')
                    },
                    {
                        name: 'Philipp Heine',
                        img: 'team/2020-11-17_ON_team_philipp-heine.jpg',
                        position: this.$t('index.team.heine1'),
                        additional: this.$t('index.team.heine2')
                    },
                    {
                        name: 'Robert Rentz',
                        img: 'team/2020-11-17_ON_team_robert-rentz.jpg',
                        position: this.$t('index.team.rentz')
                    },
                    {
                        name: 'Johannes Reimertshofer',
                        img: 'team/2020-11-17_ON_team_johannes-reimertshofer.jpg',
                        position: this.$t('index.team.reimertshofer')
                    },
                    {
                        name: 'Maren Köhler',
                        img: 'team/2021-04-22_ON_team_maren-koehler.jpg',
                        position: this.$t('index.team.koehler')
                    },
                    {
                        name: 'Kerstin Pferdekämper',
                        img: 'team/2020-11-17_ON_team_kerstin-pferdekaemper.jpg',
                        position: this.$t('index.team.pferdekaemper')
                    },
                    {
                        name: 'Carina Trieglaff',
                        img: 'team/2020-11-17_ON_team_carina-trieglaff.jpg',
                        position: this.$t('index.team.trieglaff')
                    },
                    {
                        name: 'Heiko Gabriel',
                        img: 'team/2020-11-17_ON_team_heiko-gabriel.jpg',
                        position: this.$t('index.team.gabriel')
                    },
                    {
                        name: 'Franz Päßler',
                        img: 'team/2021-04-22_ON_team_franz-paessler.jpg',
                        position: this.$t('index.team.paessler')
                    },
                    {
                        name: 'Stefanie Herrmann',
                        img: 'team/2020-11-17_ON_team_stefanie-herrmann.jpg',
                        position: this.$t('index.team.herrmann')
                    }
                ]
            }
        },
      mounted() {

            this.$nextTick(() => {
                let module = require('tiny-slider/src/tiny-slider.module');

                module.tns({
                    container: this.$refs.slider,
                    items: 1,
                    slideBy: 1,
                    loop: true,
                    nav: false,
                    gutter: 30,
                    edgePadding: 15,
                    controlsPosition: 'bottom',
                    controlsText: ["zurück", "weiter"],
                    preventScrollOnTouch: 'auto',
                    responsive: {
                        375: {
                            items: 2,
                            gutter: 40,
                            edgePadding: 30
                        },
                        768: {
                            items: 3,
                            gutter: 32,
                        },
                        1000: {
                            items: 3,
                            gutter: 40,
                            edgePadding: 100
                        },
                        1200: {
                            items: 3,
                            gutter: 49,
                            edgePadding: 100
                        },
                        1700: {
                            items: 4,
                            gutter: 65,
                            edgePadding: 130
                        }
                    }
                });
            });
        }
    }
</script>

<style lang="scss">

    .team {
        margin-left: -15px;
        margin-right: -15px;
        margin-top: 30px;

        .image-wrapper {

            img {
                @include border-radius(50%);
                overflow: hidden;
            }
        }

        .h5 {
            margin-top: 20px;

            & + p {
                margin-top: 5px;
            }
        }
    }

    @media only screen and ($breakpoint375) {

        .team {
            margin-left: -30px;
            margin-right: -30px;
        }
    }

    @media only screen and ($breakpoint768) {

        .team {
            margin-left: 0;
            margin-right: 0;
        }
    }

    @media only screen and ($breakpoint1000) {

        .team {

            .h5 {
                margin-top: 30px;
            }
        }
    }
</style>