<template>
    <header :class="scrollClass">
        <a href="#" v-scroll-to="'#stage'" class="logo">
            <img alt="ONDIGO Logo"
                 width="2056"
                 height="693"
                 :src="'/img/' + formattedLogoSrc" />
            <img class="scroll-logo"
                 alt="ONDIGO Logo klein"
                 width="125"
                 height="24"
                 src="/img/2021-04-23_ON_logo-scroll.svg" />
        </a>
        <nav>
            <a href="#" v-bind:class="{'active' : introActive}" v-scroll-to="'#intro'">Agentur</a>
            <a href="#" v-bind:class="{'active' : stackActive}" v-scroll-to="'#stack'">Leistungen</a>
            <a href="#" v-bind:class="{'active' : projectActive}" v-scroll-to="'#projects'">Projekte</a>
            <a href="#" v-bind:class="{'active' : customerActive}" v-scroll-to="'#customer'">Kunden</a>
            <a href="#" v-bind:class="{'active' : jobsActive}" v-scroll-to="'#jobs'">Jobs</a>
            <a href="#" v-bind:class="{'active' : contactActive}" v-scroll-to="'#contact'" id="btn-navigation" class="btn cta">Kontakt</a>
        </nav>
        <div class="language-switch">
            <NuxtLink v-for="locale in locales" :key="locale.code" :to="switchLocalePath(locale.code)">{{ locale.name }}</NuxtLink>
        </div>
    </header>
</template>

<script>

    export default {
        data() {
            return {
                scrollClass: '',
                introActive: false,
                stackActive: false,
                projectActive: false,
                customerActive: false,
                jobsActive: false,
                reviewsActive: false,
                partnerActive: false,
                contactActive: false
            }
        },

        computed: {
          formattedLogoSrc() {
            return this.$t('logo.white') !== undefined ? this.$t('logo.white') : '2020-01-03_ON_logo_white.svg';
          },
          locales() {
            let _scope = this;

            return this.$i18n.locales.filter(i => {
              i.active = i.code === _scope.$i18n.locale;
              return i;
            });
          },
        },
        mounted() {
            this.onScroll();
            window.addEventListener('scroll', this.onScroll, {passive: true});
        },
        methods: {
            onScroll() {
              this.handleMenuActivity();

              if (window.pageYOffset <= (window.innerHeight - 1)) {
                this.scrollClass = '';
              } else {

                if(this.introActive || this.customerActive || this.reviewsActive || this.partnerActive) {
                  this.scrollClass = 'scroll bg-grey';
                } else {
                  this.scrollClass = 'scroll';
                }
              }
            },
          handleMenuActivity() {
            this.introActive = this.elementInViewport(document.getElementById('intro'));
            this.stackActive = this.elementInViewport(document.getElementById('stack'));
            this.projectActive = this.elementInViewport(document.getElementById('projects'));
            this.customerActive = this.elementInViewport(document.getElementById('customer'));
            this.jobsActive = this.elementInViewport(document.getElementById('jobs'));
            this.reviewsActive = this.elementInViewport(document.getElementById('reviews'));
            this.partnerActive = this.elementInViewport(document.getElementById('partner'));
            this.contactActive = this.elementInViewport(document.getElementById('contact'));
          },
          elementInViewport (el) {

            if(el) {

              let top = el.offsetTop;
              let height = el.offsetHeight;

              while(el.offsetParent) {
                el = el.offsetParent;
                top += el.offsetTop;
              }

              return (
                (top - 52) < window.pageYOffset &&
                (top + height - 52) > window.pageYOffset
              );
            }
          }
        }
    }
</script>

<style lang="scss">

    header {

        .main & {
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            z-index: 5;
            padding: 0 15px;

            .logo {
                position: absolute;

                img {
                    width: 116px;
                    height: 40px;

                    &.scroll-logo {
                        display: none;
                    }
                }
            }

            nav {
                display: none;
            }

            .language-switch {
                position: absolute;
                top: 10px;
                right: 15px;
                display: none;

                a {
                    font-size: 16px;
                    line-height: 23px;
                    font-family: $ff-regular;
                    color: $white;
                    text-decoration: underline;

                    &:after {
                        content: '|';
                        padding: 0 3px;
                        font-size: 16px;
                        line-height: 23px;
                        font-family: $ff-regular;
                        color: $white;
                        display: inline-block;
                    }

                    &.nuxt-link-exact-active {
                        text-decoration: none;
                    }

                    &:last-child {

                        &:after {
                            display: none;
                        }
                    }

                    @include hover () {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    @media only screen and ('orientation: portrait') {
        header {

            .main & {

                .logo {
                    right: 25px;
                    top: 25px;
                }
            }
        }
    }

    @media only screen and ('orientation: landscape') {
        header {

            .main & {

                .logo {
                    top: 0;
                    left: 25px;
                    padding: 10px;
                    background: $darkblue;
                }
            }
        }
    }

    @media only screen and ($breakpoint375) {

        header {

            .main & {
                padding: 0 30px;
            }
        }
    }

    @media only screen and ($breakpoint375) and ('orientation: portrait') {

        header {

            .main & {

                .logo {
                    right: 30px;
                    top: 30px;
                }
            }
        }
    }

    @media only screen and ($breakpoint375) and ('orientation: landscape') {

        header {

            .main & {

                .logo {
                    left: 30px;
                }
            }
        }
    }

    @media only screen and ($breakpoint768) {

        header {

            .main & {

                .logo {

                    img {
                        width: 160px;
                        height: 54px;
                    }
                }
            }
        }
    }

    @media only screen and ($breakpoint768) and ('orientation: landscape') {
        header {

            .main & {

                .logo {
                    padding: 20px;
                }
            }
        }
    }

    @media only screen and ($breakpoint768) and ('orientation: portrait') {

        header {

            .main & {

                .logo {
                    right: 32px;
                    top: 32px;
                }
            }
        }
    }

    @media only screen and ($breakpoint1000) {

        header {

            .main & {

                .logo {

                    img {
                        width: 200px;
                        height: 70px;
                    }
                }
            }
        }
    }

    @media only screen and ($breakpoint1000) and ('orientation: landscape') {
        header {

            .main & {

                .logo {
                    padding: 30px;
                }
            }
        }
    }

    @media only screen and ($breakpoint1200) {

        header {

            .main & {
                position: fixed;
                background: $white;
                height: 0;
                @include transition(.3s ease all);

                .logo {
                    @include transition(.3s ease all);
                    left: calc((100vw - 1150px) / 2);
                    padding: 35px;
                    right: auto;
                    top: 0;
                    background: $darkblue;

                    img {
                        @include transition(.3s ease all);
                        width: 238px;
                        height: 80px;
                    }
                }

                nav {
                    display: block;
                    position: absolute;
                    right: calc((100vw - 1150px) / 2);
                    top: -52px;
                    @include transition(.3s ease top);

                    a {
                        font-size: 16px;
                        line-height: 20px;
                        font-family: $ff-regular;
                        font-weight: normal;
                        color: $grey;
                        text-decoration: none;
                        padding: 16px 20px;
                        position: relative;

                        &:after {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: 50%;
                            right: 50%;
                            height: 2px;
                            background: $midblue;
                            @include transition(.3s ease all .3s);
                        }

                        @include hover() {
                            color: $midblue;
                        }

                        &.btn {
                            font-size: 14px;
                            line-height: 17px;
                            padding: 6px 22px;
                            margin-left: 40px;

                            &:after {
                                left: 0;
                                right: 0;
                                height: auto;
                                background: none;
                                @include transition(none);
                            }

                            @include hover() {
                                color: $darkgrey;
                                text-decoration: none;
                            }

                            &.active {
                                color: $darkgrey;
                            }
                        }

                        &.active {
                            color: $midblue;

                            &:after {
                                left: 0;
                                right: 0;
                            }
                        }
                    }
                }

                .language-switch {
                    top: 30px;
                    right: 36px;
                }

                &.scroll {
                    height: 52px;

                    &.bg-grey {
                        background: $lightgrey;
                    }

                    .logo {
                        background: transparent;
                        padding: 13px 0;

                        img {
                            display: none;
                            width: 124px;
                            height: 24px;

                            &.scroll-logo {
                                display: block;
                            }
                        }
                    }

                    nav {
                        top: 0;
                    }
                }
            }
        }
    }

    @media only screen and ($breakpoint1700) {

        header {

            .main & {

                .logo {
                    left: calc((100vw - 1495px) / 2);
                    padding: 44px;

                    img {
                        width: 297px;
                        height: 100px
                    }
                }

                nav {
                    right: calc((100vw - 1495px) / 2);
                }

                .language-switch {
                    top: 25px;
                    right: calc((100vw - 1495px) / 2);
                }
            }
        }
    }

    @media only screen and ($breakpoint2000) {

        header {

            .main & {

                .logo {
                    left: calc((100vw - 1600px) / 2);
                }

                nav {
                    right: calc((100vw - 1600px) / 2);
                }

                .language-switch {
                    right: calc((100vw - 1600px) / 2);
                }
            }
        }
    }
</style>
