<template>
    <main>
        <b-jumbotron class="bg-white">
            <b-row>
                <h1><span>Informationen zum Datenschutz</span></h1>
                <p>Mit diesen Datenschutzhinweisen informieren wir Sie über unseren Umgang mit Ihren personenbezogenen Daten und über Ihre Rechte nach der Europäischen Datenschutz-Grundverordnung (DSGVO) und dem Bundesdatenschutzgesetz (BDSG). Verantwortlich für die Datenverarbeitung ist die SkyGate internetworking GmbH (nachfolgend als „wir“ oder „uns“ bezeichnet).</p>
                <h3>Inhalt</h3>
                <ol>
                    <li>
                        <a href="#" v-scroll-to="'#abs-1'">Allgemeine Angaben</a>
                        <ol>
                            <li><a href="#" v-scroll-to="'#abs-1-1'">Kontakt</a></li>
                            <li><a href="#" v-scroll-to="'#abs-1-2'">Rechtsgrundlagen</a></li>
                            <li><a href="#" v-scroll-to="'#abs-1-3'">Dauer der Speicherung</a></li>
                            <li><a href="#" v-scroll-to="'#abs-1-4'">Kategorien von Empfängern der Daten</a></li>
                            <li><a href="#" v-scroll-to="'#abs-1-5'">Datenübermittlung in Drittländer</a></li>
                            <li><a href="#" v-scroll-to="'#abs-1-6'">Verarbeitung bei der Ausübung Ihrer Rechte</a></li>
                            <li><a href="#" v-scroll-to="'#abs-1-7'">Ihre Rechte</a></li>
                            <li><a href="#" v-scroll-to="'#abs-1-8'">Widerspruchsrecht</a></li>
                            <li><a href="#" v-scroll-to="'#abs-1-9'">Datenschutzbeauftragter</a></li>
                        </ol>
                    </li>
                    <li>
                        <a href="#" v-scroll-to="'#abs-2'">Datenverarbeitungen auf unserer Website</a>
                        <ol>
                            <li><a href="#" v-scroll-to="'#abs-2-1'">Verarbeitung von Server-Log-Files</a></li>
                            <li><a href="#" v-scroll-to="'#abs-2-2'">Cookies</a></li>
                        </ol>
                    </li>
                    <li>
                        <a href="#" v-scroll-to="'#abs-3'">Weitere Datenverarbeitungen</a>
                        <ol>
                            <li><a href="#" v-scroll-to="'#abs-3-1'">Kontaktaufnahme per E-Mail</a></li>
                            <li><a href="#" v-scroll-to="'#abs-3-2'">Kunden- und Interessentendaten</a></li>
                            <li><a href="#" v-scroll-to="'#abs-3-3'">Verwendung der E-Mail-Adresse zu Marketingzwecken</a></li>
                            <li><a href="#" v-scroll-to="'#abs-3-4'">Bewerbungen</a></li>
                        </ol>
                    </li>
                </ol>
                <h3 id="abs-1">Allgemeine Angaben</h3>
                <h4 id="abs-1-1">Kontakt</h4>
                <p>Wenn Sie Fragen oder Anregungen zu diesen Informationen haben oder sich wegen der Geltendmachung Ihrer Rechte an uns wenden möchten, richten Sie Ihre Anfrage bitte an</p>
                <p>SkyGate internetworking GmbH<br>Pfuelstr. 5, 10997 Berlin<br>Tel.: +49 (0)30 611 03 8-0<br>E-Mail: <a href="mailto:skygate@skygate.de">skygate@skygate.de</a></p>
                <h4 id="abs-1-2">Rechtsgrundlagen</h4>
                <p>Der datenschutzrechtliche Begriff „personenbezogene Daten“ bezeichnet alle Informationen, die sich auf einen bestimmten oder bestimmbaren Menschen beziehen. Wir verarbeiten personenbezogene Daten unter Beachtung der einschlägigen Datenschutzvorschriften, insbesondere der DSGVO und des BDSG. Eine Datenverarbeitung durch uns findet nur auf der Grundlage einer gesetzlichen Erlaubnis statt. Wir verarbeiten personenbezogene Daten nur mit Ihrer Einwilligung (§ 15 Abs. 3 TMG oder Art. 6 Abs. 1 Buchst. a DSGVO), zur Erfüllung eines Vertrags, dessen Vertragspartei Sie sind, oder auf Ihre Anfrage zur Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 Buchst. b DSGVO), zur Erfüllung einer rechtlichen Verpflichtung (Art. 6 Abs. 1 Buchst. c DSGVO) oder wenn die Verarbeitung zur Wahrung unserer berechtigten Interessen oder der berechtigten Interessen eines Dritten erforderlich ist, sofern nicht Ihre Interessen oder Grundrechte und Grundfreiheiten, die den Schutz personenbezogener Daten erfordern, überwiegen (Art. 6 Abs. 1 Buchst. f DSGVO).</p>
                <h4 id="abs-1-3">Dauer der Speicherung</h4>
                <p>Sofern sich aus den folgenden Hinweisen nichts anderes ergibt, speichern wir die Daten nur solange, wie es zur Erreichung des Verarbeitungszwecks oder für die Erfüllung unserer vertraglichen oder gesetzlichen Pflichten erforderlich ist. Solche gesetzlichen Aufbewahrungspflichten können sich insbesondere aus handels- oder steuerrechtlichen Vorschriften ergeben.</p>
                <h4 id="abs-1-4">Kategorien von Empfängern der Daten</h4>
                <p>Wir setzen im Rahmen der Verarbeitung Ihrer Daten Auftragsverarbeiter ein. Zu den durch solche Auftragsverarbeiter ausgeführten Verarbeitungsvorgängen gehören z.B. Hosting, Wartung und Support von IT-Systemen, Marketingmaßnahmen oder Akten- und Datenträgervernichtung. Bei einem Auftragsverarbeiter handelt es sich um eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des für die Datenverarbeitung Verantwortlichen verarbeitet. Auftragsverarbeiter nutzen die Daten nicht für eigene Zwecke, sondern führen die Datenverarbeitung ausschließlich für den Verantwortlichen aus und sind vertraglich zur Gewährleistung geeigneter technischer und organisatorischer Maßnahmen zum Datenschutz verpflichtet. Im Übrigen übermitteln wir Ihre personenbezogenen Daten ggf. an Stellen wie Post- und Zustelldienste, Zahlungs- und Auskunftsdienste, Hausbank, Steuerberater/Wirtschaftsprüfer oder die Finanzverwaltung. Weitere Empfänger ergeben sich ggf. aus den folgenden Hinweisen.</p>
                <h4 id="abs-1-5">Datenübermittlung in Drittländer</h4>
                <p>Der Besuch unserer Website kann mit der Übermittlung bestimmter personenbezogener Daten in Drittländer, also Länder, in denen die DSGVO nicht geltendes Recht ist, verbunden sein. Eine solche Übermittlung erfolgt in zulässiger Weise, wenn die Europäische Kommission festgestellt hat, dass in einem solchen Drittland ein angemessenes Datenschutzniveau geboten ist. Wenn ein solcher Angemessenheitsbeschluss der Europäischen Kommission nicht vorliegt, erfolgt eine Übermittlung personenbezogener Daten in ein Drittland nur beim Vorliegen geeigneter Garantien gem. Art. 46 DSGVO oder wenn eine der Voraussetzungen des Art. 49 DSGVO gegeben ist.</p>
                <p>Sofern im Folgenden nichts anderes angegeben ist, verwenden wir als geeigneten Garantien die EU-Standardvertragsklauseln für die Übermittlung personenbezogener Daten an Auftragsverarbeiter in Drittländern: <a href="https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=CELEX%3A32010D0087" target="_blank" rel="noopener">https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=CELEX%3A32010D0087</a>.<br>Sofern Sie in die Übermittlung personenbezogener Daten in Drittstaaten einwilligen, erfolgt die Übermittlung auf der Rechtsgrundlage des Art. 49 Abs. 1 Buchst. a DSGVO.</p>
                <h4 id="abs-1-6">Verarbeitung bei der Ausübung Ihrer Rechte</h4>
                <p>Wenn Sie Ihre Rechte gemäß den Art. 15 bis 22 DSGVO ausüben, verarbeiten wir die übermittelten personenbezogenen Daten zum Zweck der Umsetzung dieser Rechte durch uns und um den Nachweis hierüber erbringen zu können. Zum Zweck der Auskunftserteilung und deren Vorbereitung gespeicherte Daten werden wir nur für diesen Zweck sowie für Zwecke der Datenschutzkontrolle verarbeiten und im Übrigen die Verarbeitung nach Maßgabe des Art. 18 DSGVO einschränken.<br>Diese Verarbeitungen beruhen auf der Rechtsgrundlage des Art. 6 Abs. 1 Buchst. c DSGVO i.V.m. Art. 15 bis 22 DSGVO und § 34 Abs. 2 BDSG.</p>
                <h4 id="abs-1-7">Ihre Rechte</h4>
                <p>Als betroffene Person haben Sie das Recht, uns gegenüber Ihre Betroffenenrechte geltend zu machen. Dabei haben Sie insbesondere die folgenden Rechte:</p>
                <ul>
                    <li>Sie haben nach Maßgabe des Art. 15 DSGVO und § 34 BDSG das Recht, Auskunft darüber zu verlangen, ob und gegebenenfalls in welchem Umfang wir personenbezogene Daten zu Ihrer Person verarbeiten oder nicht.</li>
                    <li>Sie haben das Recht, nach Maßgabe des Art. 16 DSGVO von uns die Berichtigung Ihrer Daten zu verlangen.</li>
                    <li>Sie haben das Recht, nach Maßgabe des Art. 17 DSGVO und § 35 BDSG von uns die Löschung Ihrer personenbezogenen Daten zu verlangen.</li>
                    <li>Sie haben das Recht, nach Maßgabe des Art. 18 DSGVO die Verarbeitung Ihrer personenbezogenen Daten einschränken zu lassen.</li>
                    <li>Sie haben das Recht, nach Maßgabe des Art. 20 DSGVO die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten und diese Daten einem anderen Verantwortlichen zu übermitteln.</li>
                    <li>Sofern Sie uns eine gesonderte Einwilligung in die Datenverarbeitung erteilt haben, können Sie diese Einwilligung nach Maßgabe des Art. 7 Abs. 3 DSGVO jederzeit widerrufen. Durch einen solchen Widerruf wird die Rechtmäßigkeit der Verarbeitung, die bis zum Widerruf aufgrund der Einwilligung erfolgt ist, nicht berührt.</li>
                    <li>Wenn Sie der Ansicht sind, dass eine Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die Bestimmungen der DSGVO verstößt, haben Sie nach Maßgabe des Art. 77 DSGVO das Recht auf Beschwerde bei einer Aufsichtsbehörde.</li>
                </ul>
                <h4 id="abs-1-8">Widerspruchsrecht</h4>
                <p>Sie haben nach Maßgabe des Art. 21 Abs. 1 DSGVO das Recht, gegen Verarbeitungen, die auf der Rechtsgrundlage des Art. 6 Abs. 1 Buchst. e oder f DSGVO beruhen, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, Widerspruch einzulegen. Sofern durch uns personenbezogene Daten über Sie zum Zweck der Direktwerbung verarbeitet werden, können Sie gegen diese Verarbeitung gem. Art. 21 Abs. 2 und Abs. 3 DSGVO Widerspruch einlegen.</p>
                <h4 id="abs-1-9">Datenschutzbeauftragter</h4>
                <p>Sie erreichen unseren Datenschutzbeauftragten unter folgenden Kontaktdaten:</p>
                <p>E-Mail: <a href="mailto:dsb@ondigo.de">dsb@ondigo.de</a><br>Herting Oberbeck Datenschutz GmbH<br>Hallerstr. 76, 20146 Hamburg<br><a href="https://www.datenschutzkanzlei.de" target="_blank" rel="noopener">https://www.datenschutzkanzlei.de</a></p>
                <h3 id="abs-2">Datenverarbeitungen auf unserer Website</h3>
                <p>Bei der Nutzung der Website erfassen wir Informationen, die Sie selbst bereitstellen. Außerdem werden während Ihres Besuchs auf der Website automatisch bestimmte Informationen über Ihre Nutzung der Website durch uns erfasst. Im Datenschutzrecht gilt grundsätzlich auch die IP-Adresse als ein personenbezogenes Datum. Eine IP-Adresse wird jedem mit dem Internet verbundenen Gerät durch den Internetprovider zugewiesen, damit es Daten senden und empfangen kann.</p>
                <h4 id="abs-2-1">Verarbeitung von Server-Log-Files</h4>
                <p>Bei der rein informativen Nutzung unserer Website werden zunächst automatisiert (also nicht über eine Registrierung) allgemeine Informationen gespeichert, die Ihr Browser an unseren Server übermittelt. Hierzu zählen standardmäßig: Browsertyp/ -version, verwendetes Betriebssystem, aufgerufene Seite, die zuvor besuchte Seite (Referrer URL), IP-Adresse, Datum und Uhrzeit der Serveranfrage und HTTP-Statuscode. Die Verarbeitung erfolgt zur Wahrung unserer berechtigten Interessen und beruht auf der Rechtsgrundlage des Art. 6 Abs. 1 Buchst. f DSGVO. Diese Verarbeitung dient der technischen Verwaltung und der Sicherheit der Website. Die gespeicherten Daten werden nach maximal 30 Tagen gelöscht, wenn nicht aufgrund konkreter Anhaltspunkte ein berechtigter Verdacht auf eine rechtswidrige Nutzung besteht und eine weitere Prüfung und Verarbeitung der Informationen aus diesem Grund erforderlich ist. Wir sind nicht in der Lage, Sie anhand der gespeicherten Informationen als betroffene Person zu identifizieren. Die Art. 15 bis 22 DSGVO finden daher gem. Art. 11 Abs. 2 DSGVO keine Anwendung, es sei denn, Sie stellen zur Ausübung Ihrer in diesen Artikeln niedergelegten Rechte zusätzliche Informationen bereit, die Ihre Identifizierung ermöglichen.</p>
                <h4 id="abs-2-2">Cookies</h4>
                <p>Wir verwenden auf unserer Website Cookies und vergleichbare Technologien („Cookies“). Bei Cookies handelt es sich um kleine Textdateien, die durch Ihren Browser gespeichert werden, wenn Sie eine Webseite besuchen. Hierdurch wird der verwendete Browser gekennzeichnet und kann durch Webserver wiedererkannt werden. Sie haben durch Ihren Browser die volle Kontrolle über die Verwendung von Cookies. Sie können die Cookies in den Sicherheitseinstellungen Ihres Browsers jederzeit löschen. Sie können der Verwendung von Cookies durch Ihre Browsereinstellungen grundsätzlich oder für bestimmte Fälle widersprechen. Weitere Informationen hierzu bietet Ihnen das Bundesamt für Sicherheit in der Informationstechnik: <a href="https://www.bsi-fuer-buerger.de/BSIFB/DE/Empfehlungen/EinrichtungSoftware/EinrichtungBrowser/Sicherheitsmassnahmen/Cookies/cookies_node.html" target="_blank" rel="noopener">https://www.bsi-fuer-buerger.de/BSIFB/DE/Empfehlungen/EinrichtungSoftware/EinrichtungBrowser/Sicherheitsmassnahmen/Cookies/cookies_node.html</a></p>
                <p>Die Verwendung von Cookies ist zum Teil für den Betrieb unserer Website technisch notwendig und damit ohne die Einwilligung des Nutzers zulässig.</p>
                <h3 id="abs-3">Weitere Datenverarbeitungen</h3>
                <h4 id="abs-3-1">Kontaktaufnahme per E-Mail</h4>
                <p>Wenn Sie uns über die angegebene Kontakt-E-Mail eine Nachricht schicken, werden wir die übermittelten Daten zu dem Zweck verarbeiten, Ihre Anfrage zu beantworten. Wir verarbeiten diese Daten aufgrund unseres berechtigten Interesses, mit anfragenden Personen in Kontakt zu treten. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 Buchst. f DSGVO.</p>
                <h4 id="abs-3-2">Kunden- und Interessentendaten</h4>
                <p>Zur Begründung oder Durchführung der Vertragsbeziehung zu unseren Kunden und Lieferanten ist regelmäßig die Verarbeitung der uns bereitgestellten personenbezogenen Stamm-, Vertrags- und Zahlungsdaten erforderlich. Rechtgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 Buchst. b) DSGVO. Außerdem verarbeiten wir Kunden- und Interessentendaten zu Auswertungs- und zu Marketingzwecken. Diese Verarbeitungen erfolgen auf der Rechtgrundlage des Art. 6 Abs. 1 Buchst. f) DSGVO und dienen unserem Interesse, unser Angebot weiterzuentwickeln und Sie gezielt über Angebote der SkyGate internetworking GmbH zu informieren. Weitere Datenverarbeitungen können erfolgen, wenn Sie eingewilligt haben (Art. 6 Abs 1 Buchst. a) DSGVO) oder wenn dies der Erfüllung einer rechtlichen Verpflichtung dient (Art. 6 Abs. 1 Buchst. c) DSGVO).</p>
                <h4 id="abs-3-3">Verwendung der E-Mail-Adresse zu Marketingzwecken</h4>
                <p>Wir können Ihre bei Registrierung oder Bestellung angegebene E-Mail-Adresse verwenden, um Sie über eigene ähnliche von uns angebotene Produkte und Leistungen zu informieren. Die Rechtsgrundlage ist Art. 6 Abs. 1 Buchst. f DSGVO i.V.m. § 7 Abs. 3 UWG. Sie können dem jederzeit widersprechen, ohne dass hierfür andere als die Übermittlungskosten nach den Basistarifen entstehen. Dazu können Sie sich per Klick auf den in jedem Mailing enthaltenen Abmeldelink oder per E-Mail an <a href="mailto:skygate@skygate.de">skygate@skygate.de</a> abmelden.</p>
                <p>Für den Versand des Newsletters nutzen wir den Dienst CleverReach der CleverReach GmbH & Co. KG (Deutschland). Der Dienst bietet statistische Auswertungsmöglichkeiten von Nutzungsdaten an. Hierzu zählen unter anderem Informationen, ob eine E-Mail den Empfänger erreicht hat oder sie vom Server zurückgewiesen wurde. Die Daten werden nur nach unserer Weisung verarbeitet.</p>
                <h4 id="abs-3-4">Bewerbungen</h4>
                <p>Wenn Sie sich bei unserem Unternehmen bewerben, verarbeiten wir Ihre Bewerbungsdaten ausschließlich zu Zwecken, die im Zusammenhang mit Ihrem Interesse an einer aktuellen oder zukünftigen Beschäftigung bei uns und der Bearbeitung Ihrer Bewerbung stehen. Ihre Bewerbung wird nur von den relevanten Ansprechpartnern bei uns bearbeitet und zur Kenntnis genommen. Alle mit der Datenverarbeitung betrauten Mitarbeiter sind verpflichtet, die Vertraulichkeit Ihrer Daten zu wahren. Sollten wir Ihnen keine Beschäftigung anbieten können, werden wir die von Ihnen übermittelten Daten bis zu sechs Monate nach einer etwaigen Ablehnung für den Zweck aufbewahren, Fragen im Zusammenhang mit Ihrer Bewerbung und Ablehnung zu beantworten. Dies gilt nicht, sofern gesetzliche Bestimmungen einer Löschung entgegenstehen, die weitere Speicherung zum Zwecke der Beweisführung erforderlich ist oder Sie einer längeren Speicherung ausdrücklich zugestimmt haben. Rechtsgrundlage für die Datenverarbeitung ist § 26 Abs. 1 S. 1 BDSG. Sollten wir Ihre Bewerberdaten über die Dauer von sechs Monaten hinaus aufbewahren und haben Sie darin ausdrücklich eingewilligt, weisen wir Sie darauf hin, dass diese Einwilligung jederzeit gemäß Art. 7 Abs. 3 DSGVO frei widerruflich ist. Durch einen solchen Widerruf wird die Rechtmäßigkeit der Verarbeitung, die bis zum Widerruf aufgrund der Einwilligung erfolgt ist, nicht berührt.</p>
            </b-row>
        </b-jumbotron>
    </main>
</template>

<script>
  export default {
      layout: 'sub',
      head () {
          return {
              title: 'Datenschutzerklärung Digitale Markenkraft',
              meta: [
                  { name: 'description', content: 'Pflichtangaben zum Datenschutz der SkyGate internetworking GmbH' },
                  { property: 'og:title', content: 'Datenschutzerklärung Digitale Markenkraft' },
                  { property: 'og:description', content: 'Pflichtangaben zum Datenschutz der SkyGate internetworking GmbH' },
              ]
          }
      },
      nuxtI18n: {
          paths: {
              en: '/data-protection/'
          }
      }
  }
</script>