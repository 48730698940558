<template>
    <header>
        <NuxtLink :to="{ path: '/', hash: formattedAnchor}" class="icon-arrow_lightblue"></NuxtLink>
        <NuxtLink :to="localePath('/')" class="logo">
            <img alt="ONDIGO Logo"
                 width="2056"
                 height="693"
                 :src="'/img/' + formattedLogoSrc" />
        </NuxtLink>
    </header>
</template>

<script>
    export default {
        computed: {
            formattedLogoSrc() {
                return this.$t('logo.default') !== undefined ? this.$t('logo.default') : '2020-01-03_ON_logo.svg';
            },
            formattedAnchor() {
                return this.$props.anchor !== undefined ? '#' + this.$props.anchor : '';
            }
        },
        props: ['anchor']
    }

</script>

<style lang="scss">

    header {

        .sub & {
            text-align: right;
            padding: 15px 15px 0;
            position: relative;

            .icon-arrow_lightblue {
                position: fixed;
                left: 15px;
                top: 15px;
                width: 40px;
                height: 40px;
                background: rgba($white, .8);
                @include border-radius(50%);
                @include transition(.3s ease all);
                @include transform(scale(1));

                &:before {
                    width: 30px;
                    height: 30px;
                    position: relative;
                    top: 5px;
                    left: -5px;
                    display: inline-block;
                }

                @include hover () {
                    @include transform(scale(1.4));
                }
            }

            .logo {
                width: 140px;
                height: 47px;
            }
        }
    }

    @media only screen and ($breakpoint375) {

        header {

            .sub & {
                padding: 30px 30px 0;

                .icon-arrow_lightblue {
                    left: 30px;
                    top: 30px;
                }
            }
        }
    }

    @media only screen and ($breakpoint768) {

        header {

            .sub & {

                .logo {
                    width: 200px;
                    height: 67px;
                }
            }
        }
    }

    @media only screen and ($breakpoint1000) {

        header {

            .sub & {
                padding: 30px 16px 0;
                max-width: 1000px;

                .icon-arrow_lightblue {
                    width: 60px;
                    height: 60px;
                    left: calc((100vw - 1000px) / 2);

                    &:before {
                        width: 40px;
                        height: 40px;
                        top: 11px;
                        left: -9px;
                    }
                }
            }
        }
    }

    @media only screen and ($breakpoint1200) {

        header {

            .sub & {
                max-width: 1150px;
                margin: 0 auto;
                padding-left: 0;
                padding-right: 0;

                .icon-arrow_lightblue {
                    left: calc((100vw - 1150px) / 2);
                }

                .logo {
                    width: 250px;
                    height: 84px;
                }
            }
        }
    }

    @media only screen and ($breakpoint1700) {

        header {

            .sub & {
                max-width: 1495px;

                .icon-arrow_lightblue {
                    left: calc((100vw - 1495px) / 2);
                }
            }
        }
    }

    @media only screen and ($breakpoint2000) {

        header {

            .sub & {
                max-width: 1600px;

                .icon-arrow_lightblue {
                    left: calc((100vw - 1600px) / 2);
                }
            }
        }
    }

</style>