<template>
    <b-jumbotron class="bg-white" id="reviews">
        <b-row>
            <h2 class="text-center">{{ $t('index.reviews.title') }}</h2>
            <div class="quote-wrapper">
                  <div class="slider" ref="slider">
                      <div class="slide-item" v-for="(review) in reviews">
                          <figure class="image-wrapper">
                              <img class="lazyload"
                                   src=""
                                   :data-src="review.img"
                                   :alt="review.alt" />
                          </figure>
                          <div class="quote text-center">„{{ review.quote }}“</div>
                          <p class="text-center">{{ review.author }}<br><strong>{{ review.authorinfo }}</strong></p>
                      </div>
                  </div>
            </div>
        </b-row>
    </b-jumbotron>
</template>

<script>
    export default {
        name: 'reviews',
        data () {

            return {
                sliderInstance: null,
                reviews: [
                    {
                        img: '/img/reviews/2021-04-28_ON_reviews-scv.svg',
                        alt: 'Bohmeyer & Schuster',
                        quote: this.$t('index.reviews.item1.quote'),
                        author: 'Jens Schuster',
                        authorinfo: this.$t('index.reviews.item1.authorinfo')
                    },
                    {
                        img: '/img/reviews/2021-04-28_ON_reviews-pad.svg',
                        alt: 'PADERHALLE',
                        quote: this.$t('index.reviews.item2.quote'),
                        author: 'Dr. Maria Rodehuth',
                        authorinfo: this.$t('index.reviews.item2.authorinfo')
                    }
                ]
            }
        },

        mounted () {
            this.$nextTick(() => {
                let module = require('tiny-slider/src/tiny-slider.module');

                module.tns({
                    container: this.$refs.slider,
                    items: 1,
                    nav: false,
                    loop: true,
                    preventScrollOnTouch: 'auto',
                    controlsPosition: 'bottom',
                    controlsText: ["zurück", "weiter"],
                    responsive: {
                        768: {
                            edgePadding: 40,
                        },
                        1000: {
                            edgePadding: 92,
                        },
                        1200: {
                            edgePadding: 110,
                        },
                        1700: {
                            edgePadding: 200,
                        }
                    }
                });
            });
        }
    }
</script>

<style lang="scss">

    .quote-wrapper {
        max-width: 608px;
        margin-right: auto;
        margin-left: auto;
        margin-top: 30px;

        .image-wrapper {
            width: 192px;
            height: 55px;
            margin-left: auto;
            margin-right: auto;
            display: block;
            position: relative;

            img {
                position: absolute;
                max-width: 100%;
                max-height: 100%;
                width: 100%;
                top: 50%;
                left: 50%;
                @include transform(translate(-50%, -50%));
            }
        }

        .quote {
            font-weight: normal;
            font-family: $ff-regular;
            font-size: 16px;
            line-height: 23px;
            color: $darkgrey;
            margin-top: 25px;

            & + * {
                margin-top: 25px;
            }
        }
    }

    @media only screen and ($breakpoint768) {

        .quote-wrapper {

            .image-wrapper {
                width: 288px;
                height: 82px;
            }

            .quote {
                font-size: 20px;
                line-height: 27px;
                margin-top: 30px;

                & + * {
                    font-size: 20px;
                    line-height: 27px;

                    strong {
                        font-size: 20px;
                        line-height: 27px;
                    }
                }
            }
        }
    }

    @media only screen and ($breakpoint1000) {

        .quote-wrapper {
            max-width: none;

            .image-wrapper {
                width: 300px;
                height: 85px;
            }
        }
    }

    @media only screen and ($breakpoint1200) {

        .quote-wrapper {

            .image-wrapper {
                width: 350px;
                height: 100px;
            }

            .quote {
                font-size: 25px;
                line-height: 30px;
                margin-top: 30px;

                & + * {
                    margin-top: 50px;
                    font-size: 25px;
                    line-height: 30px;

                    strong {
                        font-size: 25px;
                        line-height: 30px;
                    }
                }
            }
        }
    }

    @media only screen and ($breakpoint1700) {

        .quote-wrapper {

            .image-wrapper {
                width: 450px;
                height: 128px;
            }

            .quote {
                font-size: 28px;
                line-height: 38px;

                & + * {
                    margin-top: 40px;
                    font-size: 28px;
                    line-height: 38px;

                    strong {
                        font-size: 28px;
                        line-height: 38px;
                    }
                }
            }
        }
    }
</style>