<template>
    <b-jumbotron id="prefooter" class="bg-midblue" ref="prefooter">
        <b-row>
            <h2 class="text-center">{{ $t('index.prefooter.title') }}</h2>
            <p class="big text-center">{{ $t('index.prefooter.txt1') }}<br>{{ $t('index.prefooter.txt2') }}</p>
            <div class="contact">
                <figure class="image-wrapper">
                    <picture>
                        <source media="(max-width: 767px)"
                                srcset="/img/2021-05-21_ON_fallback.png"
                                :data-srcset="require('~/assets/img/team/2020-11-17_ON_team_philipp-heine.jpg?size=70').src + ' 1x, ' + require('~/assets/img/team/2020-11-17_ON_team_philipp-heine.jpg?size=140').src + ' 2x'">
                        <source srcset="/img/2021-05-21_ON_fallback.png"
                                :data-srcset="require('~/assets/img/team/2020-11-17_ON_team_philipp-heine.jpg?size=90').src + ' 1x, ' + require('~/assets/img/team/2020-11-17_ON_team_philipp-heine.jpg?size=180').src + ' 2x'">
                        <img class="lazyload"
                             alt="Philipp Heine"
                             width="650"
                             height="650"
                             :src="require('~/assets/img/team/2020-11-17_ON_team_philipp-heine.jpg?size=20').src"
                             :data-src="require('~/assets/img/team/2020-11-17_ON_team_philipp-heine.jpg?size=90').src" />
                    </picture>
                </figure>
                <figure class="image-wrapper">
                    <picture>
                        <source media="(max-width: 767px)"
                                srcset="/img/2021-05-21_ON_fallback.png"
                                :data-srcset="require('~/assets/img/team/2020-11-17_ON_team_sven-liebchen.jpg?size=70').src + ' 1x, ' + require('~/assets/img/team/2020-11-17_ON_team_sven-liebchen.jpg?size=140').src + ' 2x'">
                        <source srcset="/img/2021-05-21_ON_fallback.png"
                                :data-srcset="require('~/assets/img/team/2020-11-17_ON_team_sven-liebchen.jpg?size=90').src + ' 1x, ' + require('~/assets/img/team/2020-11-17_ON_team_sven-liebchen.jpg?size=180').src + ' 2x'">
                        <img class="lazyload"
                             alt="Sven Liebchen"
                             width="650"
                             height="650"
                             :src="require('~/assets/img/team/2020-11-17_ON_team_sven-liebchen.jpg?size=20').src"
                             :data-src="require('~/assets/img/team/2020-11-17_ON_team_sven-liebchen.jpg?size=90').src" />
                    </picture>
                </figure>
                <figure class="image-wrapper">
                    <picture>
                        <source media="(max-width: 767px)"
                                srcset="/img/2021-05-21_ON_fallback.png"
                                :data-srcset="require('~/assets/img/team/2020-11-17_ON_team_robert-rentz.jpg?size=70').src + ' 1x, ' + require('~/assets/img/team/2020-11-17_ON_team_robert-rentz.jpg?size=140').src + ' 2x'">
                        <source srcset="/img/2021-05-21_ON_fallback.png"
                                :data-srcset="require('~/assets/img/team/2020-11-17_ON_team_robert-rentz.jpg?size=90').src + ' 1x, ' + require('~/assets/img/team/2020-11-17_ON_team_robert-rentz.jpg?size=180').src + ' 2x'">
                        <img class="lazyload"
                             alt="Robert Rentz"
                             width="650"
                             height="650"
                             :src="require('~/assets/img/team/2020-11-17_ON_team_robert-rentz.jpg?size=20').src"
                             :data-src="require('~/assets/img/team/2020-11-17_ON_team_robert-rentz.jpg?size=90').src" />
                    </picture>
                </figure>
            </div>
            <div class="btn-wrapper text-center">
                <a id="btn-footer" href="#" v-scroll-to="'#contact'" class="btn cta">{{ $t('index.prefooter.btn') }}</a>
            </div>
        </b-row>
        <div class="inner-jumbotron bg-blue">
            <b-row>
                <p class="big text-center">Pfuelstr. 5 - Aufgang VI - 10997 Berlin<br>{{ $t('index.prefooter.phone') }}: {{ $t('index.contact.phone') }}</p>
                <p class="big text-center"><a class="icon-pointer_whiteblue on-underline" rel="noopener" target="_blank" href="https://www.google.com/maps/place/SkyGate+internetworking+GmbH/@52.5027645,13.4410892,15z/data=!4m5!3m4!1s0x0:0x82aa57a11d60f824!8m2!3d52.5027645!4d13.4410892?shorturl=1">{{ $t('index.prefooter.route') }}</a></p>
            </b-row>
        </div>
    </b-jumbotron>
</template>

<script>
    import { elementInViewport } from '~/assets/js/utils';

    export default {
        name: 'prefooter',
        mounted () {
            let _scope = this;

            window.addEventListener('scroll', function () {
                if(_scope.$refs.prefooter) {

                    if(elementInViewport(_scope.$refs.prefooter)) {
                        _scope.$store.commit('handlePreFooterInViewport', true);
                    } else {
                        _scope.$store.commit('handlePreFooterInViewport', false);
                    }
                }
            }, {passive: true});
        }
    }
</script>

<style lang="scss">

    #prefooter {

        .contact {
            text-align: center;
            margin-top: 20px;

            .image-wrapper {
                width: 70px;
                height: 70px;
                display: inline-block;
                margin: 0 12px;

                img {
                    @include border-radius(50%);
                    overflow: hidden;
                    border: thin solid rgba($midgrey, .3);
                }
            }

            & + * {
                margin-top: 35px;
            }
        }

        p.big {

            & + p.big {
                margin-top: 27px;
            }
        }
    }

    @media only screen and ($breakpoint768) {

        #prefooter {

            .contact {
                margin-top: 35px;

                .image-wrapper {
                    width: 90px;
                    height: 90px;
                    margin: 0 17px;
                }
            }
        }
    }

    @media only screen and ($breakpoint1000) {

        #prefooter {

            &.jumbotron {
                padding: 100px 16px 130px;

                .inner-jumbotron {
                    margin: 100px -16px -130px;
                }
            }
        }
    }

    @media only screen and ($breakpoint1200) {

        #prefooter {

            &.jumbotron {
                padding: 100px 0 130px;

                .inner-jumbotron {
                    margin: 100px 0 -130px;
                }
            }
        }
    }

    @media only screen and ($breakpoint1700) {

        #prefooter {

            &.jumbotron {
                padding: 100px 0 220px;

                .inner-jumbotron {
                    margin: 100px 0 -220px;
                }
            }
        }
    }
</style>