<template>
    <b-jumbotron class="bg-white" id="partner">
        <b-row>
            <h2 class="text-center">{{ $t('index.partner.title') }}</h2>
            <div class="partner">
                <figure class="image-wrapper">
                    <img class="lazyload"
                         width="100"
                         height="29"
                         alt="Logo TYPO3"
                         :src="require('~/assets/img/partner/2020-10-29_ON_partner-typo3.png?size=20').src"
                         data-src="/img/partner/2020-10-29_ON_partner-typo3.svg" />
                </figure>
                <figure class="image-wrapper">
                    <img class="lazyload"
                         width="100"
                         height="13"
                         alt="Logo Hetzner"
                         :src="require('~/assets/img/partner/2020-10-29_ON_partner-hetzner.png?size=20').src"
                         data-src="/img/partner/2020-10-29_ON_partner-hetzner.svg" />
                </figure>
                <figure class="image-wrapper">
                    <img class="lazyload"
                         width="100"
                         height="71"
                         alt="Logo Display & Video 360"
                         :src="require('~/assets/img/partner/2020-10-29_ON_partner-displayandvideo360.png?size=20').src"
                         data-src="/img/partner/2020-10-29_ON_partner-displayandvideo360.svg" />
                </figure>
                <figure class="image-wrapper">
                    <img class="lazyload"
                         width="96"
                         height="72"
                         alt="Logo UXPin"
                         :src="require('~/assets/img/partner/2021-10-26_ON_partner-uxpin.png?size=20').src"
                         data-src="/img/partner/2021-10-26_ON_partner-uxpin.svg" />
                </figure>
                <figure class="image-wrapper">
                    <img class="lazyload"
                         width="100"
                         height="23"
                         alt="Logo BrowserStack"
                         :src="require('~/assets/img/partner/2020-10-29_ON_partner-browserstack.png?size=20').src"
                         data-src="/img/partner/2020-10-29_ON_partner-browserstack.svg" />
                </figure>
                <figure class="image-wrapper">
                    <img class="lazyload"
                         width="119"
                         height="31"
                         alt="Logo strapi"
                         :src="require('~/assets/img/partner/2020-10-29_ON_partner-strapi.png?size=20').src"
                         data-src="/img/partner/2020-10-29_ON_partner-strapi.svg" />
                </figure>
                <figure class="image-wrapper">
                    <img class="lazyload"
                         width="100"
                         height="18"
                         alt="Logo NuxtJS"
                         :src="require('~/assets/img/partner/2020-10-29_ON_partner-nuxtjs.png?size=20').src"
                         data-src="/img/partner/2020-10-29_ON_partner-nuxtjs.svg" />
                </figure>
                <figure class="image-wrapper">
                    <img class="lazyload"
                         width="100"
                         height="45"
                         alt="Logo Google Partner"
                         :src="require('~/assets/img/partner/2020-10-29_ON_partner-googlepartner.png?size=20').src"
                         data-src="/img/partner/2020-10-29_ON_partner-googlepartner.svg" />
                </figure>
                <figure class="image-wrapper">
                    <img class="lazyload"
                         width="78"
                         height="22"
                         alt="Logo RYTE"
                         :src="require('~/assets/img/partner/2020-10-29_ON_partner-ryte.png?size=20').src"
                         data-src="/img/partner/2020-10-29_ON_partner-ryte.svg" />
                </figure>
                <figure class="image-wrapper">
                    <img class="lazyload"
                         width="100"
                         height="39"
                         alt="Logo amazon web services"
                         :src="require('~/assets/img/partner/2020-10-29_ON_partner-amazonwebservice.png?size=20').src"
                         data-src="/img/partner/2020-10-29_ON_partner-amazonwebservice.svg" />
                </figure>
                <figure class="image-wrapper">
                    <img class="lazyload"
                         width="100"
                         height="48"
                         alt="Logo hotjar"
                         :src="require('~/assets/img/partner/2020-10-29_ON_partner-hotjar.png?size=20').src"
                         data-src="/img/partner/2020-10-29_ON_partner-hotjar.svg" />
                </figure>
                <figure class="image-wrapper">
                    <img class="lazyload"
                         width="100"
                         height="36"
                         alt="Logo Supermetrics"
                         :src="require('~/assets/img/partner/2020-10-29_ON_partner-supermetrics.png?size=20').src"
                         data-src="/img/partner/2020-10-29_ON_partner-supermetrics.svg" />
                </figure>
                <figure class="image-wrapper">
                    <img class="lazyload"
                         width="100"
                         height="24"
                         alt="Logo Sketch"
                         :src="require('~/assets/img/partner/2020-10-29_ON_partner-sketch.png?size=20').src"
                         data-src="/img/partner/2020-10-29_ON_partner-sketch.svg" />
                </figure>
                <figure class="image-wrapper">
                    <img class="lazyload"
                         width="100"
                         height="20"
                         alt="Logo CleverReach"
                         :src="require('~/assets/img/partner/2020-10-29_ON_partner-cleverreach.png?size=20').src"
                         data-src="/img/partner/2020-10-29_ON_partner-cleverreach.svg" />
                </figure>
                <figure class="image-wrapper">
                    <img class="lazyload"
                         width="100"
                         height="21"
                         alt="Logo Google Web Designer"
                         :src="require('~/assets/img/partner/2020-10-29_ON_partner-googlewebdesigner.png?size=20').src"
                         data-src="/img/partner/2020-10-29_ON_partner-googlewebdesigner.svg" />
                </figure>
                <figure class="image-wrapper">
                    <img class="lazyload"
                         width="182"
                         height="136"
                         alt="Logo Adobe"
                         :src="require('~/assets/img/partner/2021-10-26_ON_partner-adobe.png?size=20').src"
                         data-src="/img/partner/2021-10-26_ON_partner-adobe.svg" />
                </figure>
                <figure class="image-wrapper">
                    <img class="lazyload"
                         width="90"
                         height="34"
                         alt="Logo Vue.js"
                         :src="require('~/assets/img/partner/2020-10-29_ON_partner-vuejs.png?size=20').src"
                         data-src="/img/partner/2020-10-29_ON_partner-vuejs.svg" />
                </figure>
                <figure class="image-wrapper">
                    <img class="lazyload"
                         width="99"
                         height="38"
                         alt="Logo Google Analytics"
                         :src="require('~/assets/img/partner/2020-10-29_ON_partner-googleanalytics.png?size=20').src"
                         data-src="/img/partner/2021-05-18_ON_partner-googleanalytics.svg" />
                </figure>
            </div>
        </b-row>
    </b-jumbotron>
</template>

<script>
    export default {
        name: 'partner'
    }
</script>

<style lang="scss">

    .partner {
        margin: 25px -10px -5px;
        text-align: center;

        .image-wrapper {
            position: relative;
            display: inline-block;
            width: calc(33.333333% - 20px);
            vertical-align: middle;
            margin: 5px 10px;

            &:before {
                content: '';
                width: 100%;
                padding-bottom: 75%;
                display: block;
            }

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: auto;
                max-width: 80%;
                max-height: 80%;
                @include transform(translate(-50%, -50%));
            }
        }
    }

    @media only screen and ($breakpoint768) {

        .partner {
            margin: 25px -16px -10px;

            .image-wrapper {
                width: calc(25% - 32px);
                margin: 10px 16px;
            }
        }
    }

    @media only screen and ($breakpoint1000) {

        .partner {

            .image-wrapper {
                width: calc(20% - 32px);
            }
        }
    }


    @media only screen and ($breakpoint1200) {

        .partner {
            margin: 20px -25px -20px;

            .image-wrapper {
                margin: 20px 25px;
                width: calc(16.6666667% - 50px);
            }
        }
    }

    @media only screen and ($breakpoint1700) {

        .partner {
            margin: 45px -32px -25px;

            .image-wrapper {
                margin: 25px 32px;
                width: calc(16.6666667% - 64px);
            }
        }
    }
</style>