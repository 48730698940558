<template>
  <main>
      <stage />
      <introduction />
      <stack />
      <projects />
      <facts />
      <customer />
      <team />
      <offices />
      <contact />
      <reviews />
      <jobs />
      <partner />
      <prefooter />
  </main>
</template>

<script>

  export default {
      layout: 'default',
      head () {
          return {
              title: this.$t('index.meta.title'),
              meta: [
                  { name: 'description', content: this.$t('index.meta.description')},
                  { property: 'og:title', content: this.$t('index.meta.title') },
                  { property: 'og:description', content: this.$t('index.meta.description') },
              ]
          }
      },
      components: {
        Stage: () => import('@/components/content/1_stage.vue'),
        Introduction: () => import('@/components/content/2_introduction.vue'),
        Stack: () => import('@/components/content/3_stack.vue'),
        Projects: () => import('@/components/content/4_projects.vue'),
        Facts: () => import('@/components/content/5_facts.vue'),
        Customer: () => import('@/components/content/6_customer.vue'),
        Team: () => import('@/components/content/7_team.vue'),
        Offices: () => import('@/components/content/8_offices.vue'),
        Contact: () => import('@/components/content/9_contact.vue'),
        Reviews: () => import('@/components/content/10_reviews.vue'),
        Jobs: () => import('@/components/content/11_jobs.vue'),
        Partner: () => import('@/components/content/12_partner.vue'),
        Prefooter: () => import('@/components/content/13_prefooter.vue')
      }
  }
</script>
